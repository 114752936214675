import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';


export const handleDownloadPDF = (columnsSelected,setAnchorEl,setPdfDialogOpen,tableTitle,rowData) => {
    setAnchorEl(null); // Close the menu
    setPdfDialogOpen(false)
    const pdf = new jsPDF();
  
    // Add title to the PDF
    pdf.text(tableTitle, 14, 15);
    
  
    if (columnsSelected.length === 0) {
      // No columns selected, show a warning or handle as needed
      console.error('No columns selected for PDF');
      return;
    }
  
    // Create an array of data for the table
    const tableData = rowData?.map((row, index) => {
      return [index + 1, ...columnsSelected?.map((header) => row[header.field])];
    });
  
    const columns = [
      { header: 'SNo.', dataKey: 'serialNumber' },
      ...columnsSelected?.map((col) => {
        const [key] = Object.keys(col);
        return {
          header: col[key],
          dataKey: col[key],
        };
      }),
    ];
  
    // Check if there's data to display
    if (tableData.length === 0) {
      console.warn('No data to display in the PDF');
      return;
    }
  
    // Use autoTable from jspdf-autotable
    autoTable(pdf, {
      columns: columns,
      body: tableData,
      startY: 20,
      theme: 'striped', // Apply a striped theme for better readability
      headStyles: { cellWidth: 'wrap', minCellWidth: 10 },
      bodyStyles: { minCellWidth: 20, cellWidth: 'auto' },
      didParseCell: (data) => {
        // Manually set the width for the first column (serial number)
        if (data.column.index === 0) {
          data.cell.styles.cellWidth = 15; // Adjust the entire column width
          data.cell.styles.minCellWidth = 15;
          data.cell.styles.halign = 'center'; // Center the content in the first column
        }
        else if (['Request Method', 'Rule ID','ClientIP', 'Mode','Status'].includes(String(data.column.dataKey))) {
          // Adjust width for specific columns
          data.cell.styles.cellWidth = "auto";
          data.cell.styles.minCellWidth = 17;
        }
        else if (['URL','CRS Message','Impact'].includes(String(data.column.dataKey))) {
          // Adjust width for specific columns
          data.cell.styles.cellWidth = "auto";
          data.cell.styles.minCellWidth = 50;
        }
      },
    });
  
    function addFooters() {
      const pageCount = pdf.internal.pages;
      for (let i = 1; i <= pageCount.length; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.setTextColor('blue');
        pdf.text('waf.cyberwasp.net', pdf.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
      }
    }
    addFooters();
  
    // Save the PDF with a specific name
    pdf.save('logs.pdf');
  };

export const generatePDF = (rowData: any[], selectedHeaders: string[],
      setPdfDialogOpen: (open: boolean) => void) => {
    const tableTitle = 'Attack Information';
    const doc: any = new jsPDF(); // Define the type of the 'doc' object

    doc.setTextColor('#0d93d6'); // Blue color

    doc.text(tableTitle, 14, 15);

    // Check if any of the audit_logs headers are selected
    const includeAuditLogs = selectedHeaders.some(header => ['attack_category', 'impact', 'message', 'recommendation'].includes(header));
    
    
    let headers = ['#'];


   
    // Define the headers for the PDF table
     const filteredheaders = selectedHeaders.filter(header => !['attack_category', 'impact', 'message', 'recommendation'].includes(header));

     headers = headers.concat(filteredheaders)
    // If includeAuditLogs is true, add 'More Info' column
    if (includeAuditLogs) {
        headers.push('More Info');
    }

    const data = rowData.map((row, index) => {
      rowData = [index+1]
      headers.forEach(header => {
        
        filteredheaders.map(header=>{
          
          rowData.push(row[header])
        })

       
        
        if (includeAuditLogs) {
            const moreInfoLines = row['audit_logs'].map((log, idx) => {
              let info = `${idx + 1}. `;
              let hasInfo = false;

              if (selectedHeaders.includes('attack_category')) {
                  const attackCategoryLines = log.attack_category.split('\n');
                  if (attackCategoryLines.length > 1) {
                    info += `ATTACK CATEGORY: ${attackCategoryLines[0]}\n`;
                    for (let i = 1; i < attackCategoryLines.length; i++) {
                        info += `${' '.repeat(20)}${attackCategoryLines[i]}\n`;
                    }
                } else {
                    info += `ATTACK CATEGORY: ${log.attack_category}\n`;
                }
                hasInfo = true;
             
        
              }
              if (selectedHeaders.includes('impact')) {
                  const impactLines = log.impact.split('\n');
                  if (impactLines.length > 1) {
                      info += `IMPACT: ${impactLines[0]}\n`;
                      for (let i = 1; i < impactLines.length; i++) {
                          info += `${' '.repeat(20)}${impactLines[i]}\n`;
                      }
                  } else {
                      info += `IMPACT: ${log.impact}\n`;
                  }

                  const recommendationLines = log.recommendation.split('\n');
                  if (recommendationLines.length > 1) {
                      info += `RECOMMENDATION: ${recommendationLines[0]}\n`;
                      for (let i = 1; i < recommendationLines.length; i++) {
                          info += `${' '.repeat(20)}${recommendationLines[i]}\n`;
                      }
                  } else {
                      info += `RECOMMENDATION: ${log.recommendation}\n`;
                  }
                  hasInfo = true;
              }
              if (selectedHeaders.includes('message')) {
                  info += `CRS MESSAGE: ${log.message.replace(/\n/g, '\n')}\n`;
                  hasInfo = true;
              }
           
          
              return hasInfo ? info : null;
          }).filter(info => info !== null).join('\n');
          
          

            rowData.push({ content: moreInfoLines }); // Use Courier font for more info

            
          } 
          
      });

      return rowData;
  });

    const formattedArray = headers.map(item => {
      return item.split('_').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    });

    doc.autoTable({
        head: [formattedArray],
        body: data,
        startY: 20,
        theme: 'striped',
        headStyles: { cellWidth: 'wrap', minCellWidth: 2 },
        bodyStyles: { minCellWidth: 20, cellWidth: 'auto' },
        // didDrawCell: (data) => {   
        //   // Draw vertical lines between columns
        //   const { x, y, height } = data.cell;
        //   if (data.column.index < data.table.columns.length - 1) {
        //       doc.setDrawColor(192, 192, 192); // Set line color to grey
        //       doc.setLineWidth(0.1); // Set line width
        //       doc.line(x + data.cell.width, y, x + data.cell.width, y + height); // Draw vertical line
        //   }
        // },
        didParseCell: (data) => {
            // Manually set the width for the first column (serial number)
            if (data.column.index === 0) {
                data.cell.styles.cellWidth = 12; // Adjust the entire column width
                data.cell.styles.minCellWidth = 12;
                data.cell.styles.halign = 'center'; // Center the content in the first column   
            }
            if (/^(http|https):\/\/[^ "]+$/.test(data.cell.raw)) {
              // Adjust the width and alignment for URL cells
              data.cell.styles.cellWidth = 50; // Adjust the width
              data.cell.styles.minCellWidth = 50;
              data.cell.styles.halign = 'left'; // Align content to the left
          }
            
          }
    });

    function addFooters() {
        const pageCount = doc.internal.pages;
        for (let i = 1; i <= pageCount.length; i++) {
            doc.setPage(i);
            doc.setFontSize(8);
            doc.setTextColor('#0d93d6');
            doc.text('waf.cyberwasp.net', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
        }
    }
    addFooters();

    doc.save('logs.pdf');
    setPdfDialogOpen(false);
};

//   export const generatePDF = (rowData, selectedHeaders, setPdfDialogOpen) => {
//     const tableTitle = 'Attack Information';
//     const doc = new jsPDF();

//     doc.setTextColor('#0d93d6'); // Blue color

//     doc.text(tableTitle, 14, 15);

//     // Check if any of the audit_logs headers are selected
//     const includeAuditLogs = selectedHeaders.some(header => ['attack_category', 'impact', 'message', 'recommendation'].includes(header));

//     // Define the headers for the PDF table
//     let headers = ['#', 'severity', 'status'];

//     // If audit_logs headers are selected, exclude them and add a 'More Info' column
//     if (includeAuditLogs) {
//         headers.push('More Info');
//     } else {
//         headers = headers.concat(selectedHeaders);
//     }

//     // Create data array with selected headers
//     const data = rowData.map((row, index) => {
//         // Initialize rowData array with serial number and basic row data
//         const rowData = [index + 1, row['severity'], row['status']];

//         // If audit_logs headers are selected, concatenate their data into a single string for 'More Info'
//         if (includeAuditLogs) {
//             const moreInfo = row['audit_logs'].map((log, idx) => (
//                 `${idx + 1}. Attack category: ${log.attack_category}\n Impact: ${log.impact}\n Message: ${log.message}\n Recommendation: ${log.recommendation}\n`
//             )).join('\n');
//             rowData.push(moreInfo);
//         } else {
//             // Otherwise, include the selected headers as individual columns
//             selectedHeaders.forEach(header => {
//                 rowData.push(row[header]);
//             });
//         }

//         return rowData;
//     });

//     autoTable(doc, {
//         head: [headers],
//         body: data,
//         startY: 20,
//         theme: 'striped',
//         headStyles: { cellWidth: 'wrap', minCellWidth: 2 },
//         bodyStyles: { minCellWidth: 20, cellWidth: 'auto' },
//         didParseCell: (data) => {
//             // Manually set the width for the first column (serial number)
//             if (data.column.index === 0) {
//                 data.cell.styles.cellWidth = 12; // Adjust the entire column width
//                 data.cell.styles.minCellWidth = 12;
//                 data.cell.styles.halign = 'center'; // Center the content in the first column
//             }
//         },
//     });

//     function addFooters() {
//         const pageCount = doc.internal.pages;
//         for (let i = 1; i <= pageCount.length; i++) {
//             doc.setPage(i);
//             doc.setFontSize(8);
//             doc.setTextColor('#0d93d6');
//             doc.text('waf.cyberwasp.net', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
//         }
//     }
//     addFooters();

//     doc.save('logs.pdf');
//     setPdfDialogOpen(false);
// };



// export const generatePDF = (rowData, selectedHeaders, setPdfDialogOpen) => {
//     const tableTitle = 'Attack Information';
//     const doc = new jsPDF();

//     doc.setTextColor('#0d93d6'); // Red color

//     doc.text(tableTitle, 14, 15);
    

//     // Flatten rowData and include audit_logs
//     const flattenedData = rowData.flatMap(row => {
//         return row.audit_logs.map(log => ({
//             ...row,
//             ...log
//         }));
//     });


//     function capitalizeHeader(header) {
//       return header.replace(/_/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
//   }

//     // Define the desired order of headers
//     const desiredOrder = ['#', 'request_method', 'status','client_ip','severity','attack_category','event_time','url','message','impact']; // Define your headers in the desired order

//     // Extract unique keys from selected headers and sort them based on desired order
//     const headers = desiredOrder.filter(header => selectedHeaders.includes(header));
//     const columns = desiredOrder.filter(header => selectedHeaders.includes(header)).map(capitalizeHeader);

//     // Create data array with selected headers
//     const data = flattenedData.map((row, index) => {
//         const rowData = headers.map(header => row[header]); // Convert header to lowercase and remove underscores before accessing row
//         return [index + 1, ...rowData]; // Prepend serial number to each row
//     });

//     autoTable(doc, {
//         head: [['#', ...columns]], // Include '#' as the header for the serial numbers
//         body: data,
//         startY: 20,
//         theme: 'striped',
//         headStyles: { cellWidth: 'wrap', minCellWidth: 2 },
//         bodyStyles: { minCellWidth: 20, cellWidth: 'auto' },
//         didParseCell: (data) => {
//           // Manually set the width for the first column (serial number)
//           if (data.column.index === 0) {
//             data.cell.styles.cellWidth = 12; // Adjust the entire column width
//             data.cell.styles.minCellWidth = 12;
//             data.cell.styles.halign = 'center'; // Center the content in the first column
//           }
//           else if ([1,2,3,4,5].includes(data.column.index)) {
//             data.cell.styles.cellWidth = "auto";
//             data.cell.styles.minCellWidth = 16;
//           }
//           else {
//             // Adjust width for specific columns
//             data.cell.styles.cellWidth = "auto";
//             data.cell.styles.minCellWidth = 40;
//           }
//         },
//     });

//     function addFooters() {
//         const pageCount = doc.internal.pages;
//         for (let i = 1; i <= pageCount.length; i++) {
//             doc.setPage(i);
//             doc.setFontSize(8);
//             doc.setTextColor('#0d93d6');
//             doc.text('waf.cyberwasp.net', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
//         }
//     }
//     addFooters();

//     doc.save('logs.pdf');
//     setPdfDialogOpen(false);
// };


  
//   export const generatePDF = (rowData,selectedHeaders,setPdfDialogOpen) => {
//     const tableTitle = 'Attack Information'
//     const doc:any = new jsPDF();

//     doc.text(tableTitle, 14, 15);


//     const rowDataForPDF = rowData.map(row => {
//         const rowDataForLogs = row.audit_logs.map(log => ({
//             ...row,
//             ...log
//         }));
//         return rowDataForLogs;
//     }).flat();

//     const headers = selectedHeaders.map(header => {

//         if (header === 'audit_logs') {
//             return Object.keys(rowDataForPDF[0].audit_logs[0]);
//         }
//         return header;
//     }).flat();

//     const data = rowDataForPDF.map(row => {
//         return selectedHeaders.map(header => {
//             if (header === 'audit_logs') {
//                 return JSON.stringify(row.audit_logs);
//             }
//             return row[header];
//         });
//     });

//     autoTable(doc, {
//       columns: headers,
//       body: data,
//       startY: 20,
//       theme: 'striped', // Apply a striped theme for better readability
//       headStyles: { cellWidth: 'wrap', minCellWidth: 10 },
//       bodyStyles: { minCellWidth: 20, cellWidth: 'auto' },
//     });

//     function addFooters() {
//       const pageCount = doc.internal.pages;
//       for (let i = 1; i <= pageCount.length; i++) {
//         doc.setPage(i);
//         doc.setFontSize(8);
//         doc.setTextColor('blue');
//         doc.text('waf.cyberwasp.net', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
//       }
//     }
//     addFooters();
  
//     doc.save('logs.pdf');
//     setPdfDialogOpen(false);
// };


