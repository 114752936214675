import { IS_LOADING_DATA, LOADER,LINE_CHART_LOADER, IS_LOADING_ATTACKS_BLOCKED } from "../../constants/actions";

// Action
export const setLoader = (loading) => {
    return {
        type: LOADER,
        payload: loading,
    };
};



export const setLoadingData = (loadingData) => {
    return {
        type: IS_LOADING_DATA,
        payload: loadingData,
    };
};



export const setIsLoadingLineChart = (isLoadingLineChart) => {
    return {
        type: LINE_CHART_LOADER,
        payload: isLoadingLineChart,
    };
};

export const setIsLoadingAttacksBlocked = (isLoading) => {
    return {
        type: IS_LOADING_ATTACKS_BLOCKED,
        payload: isLoading,
    };
};

