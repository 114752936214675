import { Box, Button, TextField } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import CONSTANTS from "../../../constants/constants"
import {sendReportApi } from "./clientServices"
import { useFormik } from "formik"
import * as Yup from 'yup';
import { makeStyles } from "@material-ui/core"
import { pushNotification } from "../../../redux/notification/notificationActions"


export const useStyles = makeStyles((theme) => ({
  
    fieldLabel: {
      marginBottom: "10px",
    },
    screenHeadingContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#4B6D8F",
        color: "#FFFFFF",
        paddingLeft:"0.8rem",
  
        [theme.breakpoints.up('xl')]: {
          padding:"1rem",
        },
  
      },
  }));

const SendReport =({clientId,setSendReportTo})=>{


    const dispatch = useDispatch()

    const [initialValues, setInitialValues] = useState({
        client_id:clientId,
        glossary_id:"",
        cyberwasp_ui:"",
        email_id:""
      });

      const formPayload = (values: any) => ({
        client_id:values.client_id,
        glossary_id:values.glossary_id,
        cyberwasp_ui:values.cyberwasp_ui,
        email_id:values.email_id
        
      });


      const postFormValues = (values: any) => {
        const postData = formPayload(values);
        handlePostApiRequest(postData)
      };
    
      const handlePostApiRequest = async (data) => {
        const response = await sendReportApi(data);
        
        // console.log("response ",response)
        if (response?.success) {
            setSendReportTo("")
          dispatch(
            pushNotification({
              isOpen: true,
              message: response.message,
              type: CONSTANTS.SUCCESS,
              })
            );
           
          } else {
            setSendReportTo("")
            dispatch(
              pushNotification({
                isOpen: true,
                message: response.error,
                type: CONSTANTS.ERROR,
              })
            );
          }
        };
  

    const handleSubmit = async (values: any, setSubmitting: any) => {
        setSubmitting(true);
        postFormValues(values);
      };

      const formOne = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            client_id: Yup.string(),
            glossary_id:Yup.string()
                .required("Please Enter the id for glossary"),
            cyberwasp_ui:Yup.string()
                .required("Please Enter cyberwasp UI url"),
            email_id:Yup.string()
                .email("Please Enter the email to send waf report to"),
            
            
        }),
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          },
          enableReinitialize: true,
      });

     const classes = useStyles()
    return (
    
        <form onSubmit={formOne.handleSubmit} style={{ display: "flex",flexDirection:"column" }}>
            
            <Box
                display={"flex"}
                flexDirection={"column"}
                mt={"2%"}
                gap={"1rem"}
                p={"1em"}>
                    <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.fieldLabel}>Client ID<span style={{color:"red"}}>*</span></label>
                    <TextField
                        id="client-id"
                        name="client_id"
                        // placeholder="Enter the Email Id"
                        size="small"
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.client_id}
                        helperText={
                            formOne.touched.client_id && formOne.errors.client_id
                        }
                        error={
                            formOne.touched.client_id && Boolean(formOne.errors.client_id)
                        }
                        sx={{ width: "12rem" }}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.fieldLabel}>Email ID<span style={{color:"red"}}>*</span></label>
                    <TextField
                        id="email-id"
                        name="email_id"
                        placeholder="Enter the Email Id"
                        size="small"
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.email_id}
                        helperText={
                            formOne.touched.email_id && formOne.errors.email_id
                        }
                        error={
                            formOne.touched.email_id && Boolean(formOne.errors.email_id)
                        }
                        sx={{ width: "12rem" }}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.fieldLabel}>Cyberwasp UI<span style={{color:"red"}}>*</span></label>
                    <TextField
                        id="cyberwasp-ui"
                        name="cyberwasp_ui"
                        placeholder="Enter the Cyberwasp UI"
                        size="small"
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.cyberwasp_ui}
                        helperText={
                            formOne.touched.cyberwasp_ui && formOne.errors.cyberwasp_ui
                        }
                        error={
                            formOne.touched.cyberwasp_ui && Boolean(formOne.errors.cyberwasp_ui)
                        }
                        sx={{ width: "12rem" }}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.fieldLabel}>Glossary ID<span style={{color:"red"}}>*</span></label>
                    <TextField
                        id="glossary-id"
                        name="glossary_id"
                        placeholder="Enter the glossary Id"
                        size="small"
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.glossary_id}
                        helperText={
                            formOne.touched.glossary_id && formOne.errors.glossary_id
                        }
                        error={
                            formOne.touched.glossary_id && Boolean(formOne.errors.glossary_id)
                        }
                        sx={{ width: "12rem" }}
                        />
                    </Box>
            </Box>

            <Box sx={{alignSelf:"center"}}>
              <Button 
                  sx={{alignSelf:"center",mb:"0.4rem",color:'#FFFFFF'}}
                  variant="contained" type="submit"  disabled={formOne.isSubmitting}>
                  {formOne.isSubmitting? "Sending...":"Send Report"}
              </Button>
              <Button 
                  onClick={()=>setSendReportTo("")}
                  sx={{ml:2,mb:"0.4rem",color:"#FFFFFF"}}
                  variant="contained">
                close
              </Button>
            </Box>
        </form>
       
    )

}

export default SendReport