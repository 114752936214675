import { createTheme } from "@mui/material";
import SIZE from "./font_size.json";

const primary = "#1FA7D3";
const primaryLight = "#1FA7D3";
const secondary = "#fff";
const error = "#f17b7b";

export const INITIAL_THEME = createTheme({

  palette: {
    primary: {
      main: primary,
      light: primaryLight,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
  },
  typography: {
    fontFamily: "'Barlow', sans-serif",
    h1: {
      fontSize: '1.2rem'
    },
    h2: {
      fontSize: '1rem',
      color:primary,
    },
    h3: {
      fontSize: '0.8rem',
      color:primary

    },
    h4: {
      fontSize: '0.7rem',
      color: primary,

    },
    h5: {
      fontSize: '0.6rem',
      fontWeight: "bold",
      color:primary

    },
    h6: {
      fontSize: '0.5rem',
      color:primary

    },
    subtitle1: {
      fontSize: SIZE[4],
      fontWeight: "normal",
      color:primary

    },
    subtitle2: {
      fontSize: SIZE[3],
      fontWeight: "normal",
      color: primary
    },
    body1: {
      fontSize: '0.9rem',
      fontWeight: "normal",
    },
    body2: {
      fontSize: '1rem',
      fontWeight: "normal",
      color: '#4D4D4D',
    },
    button: {
      fontFamily: "Barlow",
      fontWeight:600,
      textTransform: "none",
    },
    caption: {
      fontSize: SIZE[0],
      fontWeight: "normal",
      color: primary,
    },
    overline: {
      fontSize: SIZE[0],
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  
  },
  
});
