import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface LoadingDotsProps {
    text:string;
    id:string;
}

export const LoadingDots = ({ text,id }:LoadingDotsProps) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
      const interval = setInterval(() => {
          setDots((prevDots) => (prevDots.length >= 3 ? '' : prevDots + '.'));
      }, 500);

      return () => clearInterval(interval);
  }, []);


  return (
      <Typography id={id} variant="h2" fontSize={'1.4rem'} >{dots}</Typography>
  );
};

