import { useEffect, useState } from "react";
 
 
import { Box, Card, CardContent, Typography } from "@mui/material";
import { formatNumber } from "../../utils/formatNumber";
import { LoadingDots } from "../DotsLoader";
 
 
export const GRADIENT_COLORS = ['#FFFFFF', '#BED9E3'];

interface VerticalCardProps {
  isLoading:boolean;
  title:string;
  number:any;
  imageUrl:any;
  onClick:any;
  id:string;
}
 
export const VerticalCard = ({id,isLoading, title, number, imageUrl, onClick }:VerticalCardProps) => {
  const isAttacksBlocked = title === "Attacks Blocked";
  const isPer = title === 'Attack Percentage';
 
  return (
    <Card
      id={id}
      onClick={onClick}
      sx={{
        cursor: isAttacksBlocked ? "pointer" : "default",
        borderRadius: "0.5rem",
        background: `linear-gradient(to right, ${GRADIENT_COLORS.join(',')})`,
        color: '#000',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",gap:'0.6rem',p:"0.8rem 0rem 0.5rem 0rem",
        boxShadow: 'none',
        "&:hover": {
          boxShadow: isAttacksBlocked ? "0 0 10px rgba(0, 0, 0, 0.9)" : "none",
          color: isAttacksBlocked ? "#29A5D4" : "#000",
        },
      }}
    >
      <img id={`vertical-card-image-${id}`} src={imageUrl} className={"IconImage"} alt={title} /> {/* Adjust margin here */}
      <CardContent
        id={`vertical-card-content-${id}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          height:"3rem",
          padding: "0px 0px 0px 0px !important",
          gap:'0.3rem',
          alignItems: "center", // Align contents to start to prevent shrinking
        }}
      >
         {isLoading ? (
          <Typography id={`vertical-card-title-${id}`} lineHeight={"1rem"} variant="h2">{title}</Typography>
        ) : (
          <Typography id={`vertical-card-title-${id}`} lineHeight={"1rem"} variant="h2">{title}</Typography>
        )}
        {/* <Typography lineHeight={"1.2rem"} variant="h2" sx={{ marginBottom: '0.5rem' }}>{title}</Typography> */}
        {isLoading ? 
          <LoadingDots id="loading-vertical-card-count" text="" /> : (
          <Typography id={`vertical-card-count-${id}`} sx={{textAlign:"center"}} variant="h1" title={number}>
            {isPer ? `${number}%` : formatNumber(number)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

