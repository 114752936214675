
import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";

import { addOrEditOrDeleteClient } from './clientServices';
import CONSTANTS from '../../../constants/constants';
import { makeStyles } from '@material-ui/core';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CustomFormLabel, PopUpHeading } from '../reuseStyles';
import { pushNotification } from '../../../redux/notification/notificationActions';

export const useStyles = makeStyles((theme) => ({
  
    fieldLabel: {
        marginBottom: "10px",

      },
    screenHeadingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#4B6D8F",
      color: "#FFFFFF",
      paddingLeft:"0.8rem",

      [theme.breakpoints.up('xl')]: {
        padding:"1rem",
      },

      
    },
  
 
}));

const EditClient = ({selectedClient, setShow,handleGetAllClients}) =>{
    
    const dispatch = useDispatch()
    const [initalStateValues, setInitalStateValues] = useState({
      client_name:"",
      business_owner:"",
      bo_email:"",
      bo_phone:"",
      technical_owner:"",
      to_email:"",
      to_phone:""

      
      });
    


    const formPayload = (values: any) => ({
      client_name: values.client_name,
      business_owner:values.business_owner,
      bo_email:values.bo_email,
      bo_phone:values.bo_phone,
      technical_owner:values.technical_owner,
      to_email:values.to_email,
      to_phone:values.to_phone
      });

    const postFormValues = (values: any) => {
        const postData = formPayload(values);

        console.log("post data ",postData)

        handlePostApiRequest(postData);
      };

    const handlePostApiRequest = async (data) => {
      const response = await addOrEditOrDeleteClient("update", selectedClient.client_id, data);
      console.log("resp",response)
      if (response?.success) {
        setShow(false);
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        handleGetAllClients();
      } else {
        setShow(false);
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.error,
            type: CONSTANTS.ERROR,
          })
        );
      }
    };

      const handlePrefillData = async (selectedClient) => {
        // const response = await getAllPaginatedClients(1,1,selectedClient.client_name);
        // const response = await getUserDetails(selectedUser);
        // formOne.setFieldValue("client_id", selectedClient.client_id);
        formOne.setFieldValue("client_name", selectedClient.client_name);
        formOne.setFieldValue("business_owner", selectedClient.business_owner);
        formOne.setFieldValue("bo_email", selectedClient.bo_email);
        formOne.setFieldValue("bo_phone", selectedClient.bo_phone);
        formOne.setFieldValue("technical_owner", selectedClient.technical_owner);
        formOne.setFieldValue("to_email", selectedClient.to_email);
        formOne.setFieldValue("to_phone", selectedClient.to_phone);
      };
    
      useEffect(() => {
        handlePrefillData(selectedClient);
      }, [selectedClient]);
    
      const handleSubmit = async (values: any, setSubmitting: any) => {
        setSubmitting(true);
        postFormValues(values);
      };

  
    const formOne = useFormik({
        initialValues: initalStateValues,
        validationSchema: Yup.object({
          client_name: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Client name must contain only letters and numbers')
            .required("Client Name is Requried")
            // .max(10, "Maximum length is 10 Characters")
            // .min(4, "Minimum length of is 4 Characters")
            .trim(),
          business_owner: Yup.string()
            .matches(/^(?=.*[A-Za-z])[\w ]+$/, 'Business Owner must contain only letters')
            .required("business owner's name is Requried"),
          bo_email:Yup.string()
            .email("Please Enter a Valid Email Id"),
          bo_phone:Yup.string()
            .notRequired(), // Makes the field optional
            // .matches(/^\d{0,10}$/, 'Phone number must be at most 10 digits')
            // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')s,
            // .max(10, "Maximum length of phone number is 10 digits"),
          technical_owner: Yup.string()
            .matches(/^(?=.*[A-Za-z])[\w ]+$/, 'Technical Owner must contain only letters')
            .required("business owner's name is Requried"),
          to_email:Yup.string()
            .email("Please Enter a Valid Email Id"),
          to_phone:Yup.string()
            .notRequired() // Makes the field optional
            // .matches(/^\d{0,10}$/, 'Phone number must be at most 10 digits')
            // .max(10, "Maximum length of phone number is 10 digits"),
            // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')

        }),
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          },
          enableReinitialize: true,
      });
  


    const classes = useStyles()

    return (
        <Box padding={"2%"}>
        
            <form onSubmit={formOne.handleSubmit}>
            <Box 
            sx={{
            position:"absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor:"#FFFFFF",
            overflow:"auto",
            // zIndex:1,
            "@media (max-width:600px)":{
              width:"18rem",height:"20rem"
            },
            "@media (min-width:601px)":{
              width:"24rem",height:"22rem"
            },
            "@media (min-width:768px)":{
              width:"30rem",height:"25rem"
            },
            "@media (min-width:1024px)":{
              width:"38rem",height:"26rem"
            },
            "@media (min-width:1366px)":{
              width:"44rem",height:"28rem"
            },
            "@media (min-width:2560px)":{
              width:"65rem",height:"30rem"
            },
            "@media (min-width:3200px)":{
              width:"65rem",height:"30rem"
            },
          }}>
              
              <Box className={classes.screenHeadingContainer}>
              <PopUpHeading variant='h4'>Update Client</PopUpHeading>
                <Box>
                  <Button
                    sx={{ color: "#FFFF" }}
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <CloseOutlinedIcon />
                  </Button>
                </Box>
              </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  // alignItems={"center"}
                  mt={"2%"}
                  gap={"1rem"}
                  p={"0.8rem"}
                >
                
                <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>Client Name</CustomFormLabel>
                    <TextField
                      id="client-name"
                      name="client_name"
                      size="small"
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}                 
                      value={formOne.values.client_name}
                      helperText={
                        formOne.touched.client_name && formOne.errors.client_name
                      }
                      error={
                        formOne.touched.client_name && Boolean(formOne.errors.client_name)
                      }
                      sx={{ width: "36rem" }}
                    />
                  </Box>


                <Box>
            <label className={classes.fieldLabel}>Business Owner</label>

              <TextField
                id="business-owner"
                name="business_owner"
                size="small"
                onBlur={formOne.handleBlur}
                onChange={formOne.handleChange}
                value={formOne.values.business_owner}
                helperText={
                  formOne.touched.business_owner && formOne.errors.business_owner
                }
                error={
                  formOne.touched.business_owner && Boolean(formOne.errors.business_owner)
                }
                sx={{ width: "36rem" }}
              />
                </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>BO Email <span style={{color:"red"}}>*</span></label>
                <TextField
                  id="bo-email"
                  name="bo_email"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.bo_email}
                  helperText={formOne.touched.bo_email && formOne.errors.bo_email}
                  error={
                    formOne.touched.bo_email && Boolean(formOne.errors.bo_email)
                  }
                  sx={{ width: "36rem" }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>BO Phone <span style={{color:"red"}}>*</span></label>
                <TextField
                  id="bo-phone"
                  name="bo_phone"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.bo_phone}
                  helperText={
                    formOne.touched.bo_phone && formOne.errors.bo_phone
                  }
                  error={
                    formOne.touched.bo_phone &&
                    Boolean(formOne.errors.bo_phone)
                  }
                  sx={{ width: "36rem" }}
                />
                </Box>
              <Box>
            <label className={classes.fieldLabel}>Technical Owner</label>

            <TextField
              id="technical-owner"
              name="technical_owner"
              size="small"
              onBlur={formOne.handleBlur}
              onChange={formOne.handleChange}
              value={formOne.values.technical_owner}
              helperText={
                formOne.touched.technical_owner && formOne.errors.technical_owner
              }
              error={
                formOne.touched.technical_owner && Boolean(formOne.errors.technical_owner)
              }
              sx={{ width: "36rem" }}
            />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
            <label className={classes.fieldLabel}>TO Email <span style={{color:"red"}}>*</span></label>
            <TextField
              id="to-email"
              name="to_email"
              size="small"
              onBlur={formOne.handleBlur}
              onChange={formOne.handleChange}
              value={formOne.values.to_email}
              helperText={formOne.touched.to_email && formOne.errors.to_email}
              error={
                formOne.touched.to_email && Boolean(formOne.errors.to_email)
              }
              sx={{ width: "36rem" }}
            />
              </Box>

            <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>TO Phone</label>
              <TextField
                id="to-phone"
                name="to_phone"
                size="small"
                onBlur={formOne.handleBlur}
                onChange={formOne.handleChange}
                value={formOne.values.to_phone}
                helperText={
                  formOne.touched.to_phone && formOne.errors.to_phone
                }
                error={
                  formOne.touched.to_phone &&
                  Boolean(formOne.errors.to_phone)
                }
                sx={{ width: "36rem" }}
              />
              </Box>
           
              <Button 
                variant={"contained"} 
                sx={{textTransform:"none",alignSelf:"flex-start"}}
              type="submit"
                disabled={formOne.isSubmitting}>
                  Update
                </Button>
                </Box>
                </Box>
            </form>
        
        </Box>
    )

}

export default EditClient