
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Box, Popover } from '@mui/material';
import { Resizable } from 're-resizable';
import AgGridTable from '../agGridTable/AgGridTable';

const PopupComponent = ({ isOpen, handleClose,rowsPerPage,setFilterValue,
    page,data,handleChangeRowsPerPage,handleChangePage,tableTitle,tableId,isLoading}) => {

  return (
    <Popover
      sx={{zIndex:1}}
      id={'attackBlocked'}
      open={isOpen}
      onClose={handleClose}
       anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
        }}
       transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
    >
     
        <Box sx={{width:"70rem"}}>
           <AgGridTable 
            setFilterValue={setFilterValue} tableId={tableId} isPopUp={true} handleClosePopUp={handleClose}
            tableTitle={tableTitle} page={page}
            rowsPerPage={rowsPerPage} data={data}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage} isLoading={isLoading}/>
        </Box>
    </Popover>
  );
};

export default PopupComponent;
