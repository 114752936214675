import React, { useState, useEffect } from 'react';
import EulaModal from './EulaModal';
import axios from 'axios';
import CONSTANTS from '../../constants/constants';
import keycloak from '../../keycloak';
import { Box } from '@mui/material';
import { EULA_CHECK_ENDPOINT, EULA_SET_ENDPOINT } from '../../constants/endpoints';
import { request } from '../../services/request';
import NoAssignedDomainsPage from '../common/NoAssigedDomainPage';

interface EulaWrapperProps {
  children: React.ReactNode;
}

const EULA_API_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}`;

const EulaWrapper: React.FC<EulaWrapperProps> = ({ children }) => {
  const [showEula, setShowEula] = useState(false);
  const [eulaStatusFailed, setEulaStatusFailed] = useState(false); // State to track if EULA status check failed

  const user_id = sessionStorage.getItem(CONSTANTS.USER_ID);

  useEffect(() => {
    const checkEulaStatus = async () => {
      try {
        // Check if the EULA status is already stored in local storage
        const storedEulaStatus = sessionStorage.getItem('is_accepted_eula');

        if (storedEulaStatus === null) {
          // EULA status not stored, check with the API
          const EULA_CHECK_URL = `${EULA_API_URL}${EULA_CHECK_ENDPOINT}?user_id=${user_id}`;
          const response: any = await request.get(EULA_CHECK_URL);
          const isAcceptedEula = response.is_accepted_eula;
          setShowEula(!isAcceptedEula);

          // Store the EULA status in local storage
          sessionStorage.setItem('is_accepted_eula', isAcceptedEula.toString());
        } else {
          // EULA status already stored, use the stored value
          setShowEula(!JSON.parse(storedEulaStatus));
        }
      } catch (error) {
        console.error('Failed to check EULA status:', error);
        setEulaStatusFailed(true); // Set state to indicate EULA status check failed
        // Optionally, you could log out the user here if this is a critical error
      }
    };

    if (sessionStorage.getItem(CONSTANTS.REACT_TOKEN)) {
      checkEulaStatus();
    }
  }, []);

  const handleAcceptEula = async () => {
    try {
      const EULA_ACCEPT_URL = `${EULA_API_URL}${EULA_SET_ENDPOINT}`;

      const data = {
        user_id: user_id,
        reset: false,
      };
      const response: any = await request.post(EULA_ACCEPT_URL, data);
      setShowEula(false);

      // Store the EULA status in local storage
      sessionStorage.setItem('is_accepted_eula', 'true');
    } catch (error) {
      console.error('Failed to accept EULA:', error);
      // Handle error (show message to user, etc.)
    }
  };

  const handleRejectEula = () => {
    sessionStorage.clear();
    keycloak.logout();
  };

  if (eulaStatusFailed) {
    // Render the NoAssignedDomainsPage if EULA status check failed
    return <NoAssignedDomainsPage onLogout={handleRejectEula} />;
  }

  return (
    <>
      <EulaModal isOpen={showEula} onAccept={handleAcceptEula} onReject={handleRejectEula} />
      {!showEula && children}
    </>
  );
};

export default EulaWrapper;
