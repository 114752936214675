
import { Box, Typography } from "@mui/material";
import NoAttacks from "../../../assets/svg/noAttacks.svg"

interface NoAttacksPageProps {
    id:string;
    title:string;
}

const NoAttacksPage = ({id,title}:NoAttacksPageProps)=>{
    return (
            <Box
              id={id}
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                justifyContent={"center"}
                gap={"10%"}
                p={"2%"}
                borderRadius={'1%'}
                sx={{backgroundColor:"#FFFFFF"}}
                >
            <Typography 
             id={`${id}-title`}
            sx={{alignSelf:"center",marginBottom: "3%",}} variant="h2">
                {title}
            </Typography>
            
            <div 
               id={`${id}-content-box`}
              style={{
               display:"flex",
               flexDirection:"column",
               alignItems:"center"
                }}>
                <img  id={`${id}-image`} style={{width:"25%"}} src={NoAttacks} alt="no attacks"/>
                <Typography  id={`${id}-paragraph`} variant="body2" >No Attacks in the selected domain</Typography>
            </div>
            </Box>
        );
    
}

export default NoAttacksPage