export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

// Action Type
export const LOADER = 'LOADER';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const IS_WAF_ADMIN = 'IS_WAF_ADMIN'

export const DOMAIN_URLS_BY_CLIENT = 'DOMAIN_URLS_BY_CLIENT'

export const DOMAIN_OBJECT = 'DOMAIN_OBJECT'

export const SNACK_BAR = 'SNACK_BAR'
export const MENUS_LIST = 'MENUS_LIST'

export const BUTTON_LABEL = 'BUTTON_LABEL'

export const CUSTOM_BUTTON = 'CUSTOM_BUTTON'

export const START_AND_END_DATE_TIME = 'START_AND_END_DATE_TIME'

export const CARDS_DATA = 'CARDS_DATA'

export const TIME_LINE_DATA = 'TIME_LINE_DATA'
export const ATTACKS_BY_SEVERITY = 'ATTACKS_BY_SEVERITY'

export const ATTACKS_BY_CATEGORY = 'ATTACKS_BY_CATEGORY'

export const ATTACKS_INFORMATION_TABLE = 'ATTACKS_INFORMATION_TABLE'

export const CHART_TABLE_INFO = 'CHART_TABLE_INFO'

export const ATTACKS_BLOCKED_DATA = 'ATTACKS_BLOCKED_DATA'

export const HEAT_MAP_DATA = 'HEAT_MAP_DATA'

export const IS_LOADING_DATA = 'IS_LOADING_DATA'

export const LINE_CHART_LOADER = 'LINE_CHART_LOADER'

export const IS_LOADING_ATTACKS_BLOCKED = 'IS_LOADING_ATTACKS_BLOCKED'