import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import './chart.css'; // Import CSS file for tooltip styling
import PreLoader from '../../../../components/common/preLoader';
import { Typography } from '@mui/material';
import { formatNumber } from '../../utils/formatNumber';
import NoAttacksPage from '../NoAttacksPage';

const LINE_COLOR = '#0046FF'
const DOT_COLOR = "#DA222E"
const TEXT_COLOR = '#1FA7D3'
const GRID_COLOR = '#DDDDDD'

interface BarChartProps {
  id:string;
  data:any;
  total:any;
  format:any;
  isLoading:boolean;
}

const BarChart = ({ id,data, total, format, isLoading }:BarChartProps) => {

  const svgRef = useRef<any>();
  const tooltipRef = useRef<any>();
  const [dimensions, setDimensions] = useState({ width: 1100, height: 400 });

  useEffect(() => {
    if (!data || data?.length === 0) {
      console.log('No data provided');
      return;
    }

    const svg = d3.select(svgRef.current);

    const margin = { top: 20, right: 40, bottom: 50, left: 80 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height -margin.left - margin.top - margin.bottom;

    svg.selectAll('*').remove();

    const timeFormats = {
      '12H': d3.timeFormat('%I:%M %p'),
      '24H': d3.timeFormat('%I:%M %p'),
      // '24H': d3.timeFormat('%H:%M'),
      '1W': d3.timeFormat('%a/%d'),
      '1M': d3.timeFormat('%b %d'),
      '1Y': d3.timeFormat('%b'),
      'Custom...': d3.timeFormat('%H:%M')
    };

    const x = d3.scaleBand()
      .domain(data?.map(d => d.date)) // Use date from the data for the x-axis domain
      .range([margin.left, width + margin.left])
      .padding(0.8);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .nice()
      .range([height, margin.top]);

    const xAxis = g => g
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x)
        .tickValues(getTickValues(data, format))
        .tickFormat(d => timeFormats[format](new Date(d)))
        .tickSizeOuter(0)
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-0.5em')
      .attr('dy', '0.5em')
      .attr('transform', 'rotate(-45)');

    const yAxis = g => g
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(y).tickFormat(formatNumber).ticks(5))
      .call(g => g.select('.domain').remove());

   
    // Add grid lines along x-axis
    const xGrid = g => g
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(-height).tickFormat('').tickValues(getTickValues(data, format)));

    // Add grid lines along y-axis
    const yGrid = g => g
      .attr('class', 'grid')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickSize(-width).tickFormat('').ticks(5))
      .call(g => g.select('.domain').remove());

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    svg.append('g')
      .call(xGrid)
      .selectAll('.tick line')
      .attr('stroke', GRID_COLOR)
      .attr('stroke-opacity', 0.7);

    svg.append('g')
      .call(yGrid)
      .selectAll('.tick line')
      .attr('stroke', GRID_COLOR)
      .attr('stroke-opacity', 0.7);

   // Add grid lines on top
   svg.append('g')
   .call(d3.axisTop(x).tickSize(0).tickFormat('').tickValues(getTickValues(data, format)))
   .attr('transform', `translate(0, ${margin.top-20})`)
   .selectAll('.tick line')
   .attr('stroke', 'lightgrey')


    const barGroup = svg.append('g');

    const bars = barGroup.selectAll('rect')
      .data(data)
      .join('rect')
      .attr('x', d => x(d.date))
      .attr('y', d => y(d.value))
      .attr('height', d => y(0) - y(d.value))
      .attr('width', x.bandwidth())
      .attr('fill', 'steelblue');


       // Add invisible rectangles for tooltip interaction
    const invisibleBars = barGroup.selectAll('.invisible-rect')
      .data(data)
      .join('rect')
      .attr('class', 'invisible-rect')
      .attr('x', d => x(d.date))
      .attr('y', margin.top)
      .attr('height', height - margin.top)
      .attr('width', x.bandwidth())
      .style('opacity', 0) // Set opacity to 0
      .on('mouseover', (event, d) => {
        tooltip.style('display', 'block');
      })
      .on('mousemove', (event, d) => {
        tooltip.html(`${d.date}<br/>#Attacks: ${d.value}`)
          .style('left', `${event.pageX - 50}px`)
          .style('top', `${event.pageY - 115}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('display', 'none');
      });

    const line = d3.line()
      .x(d => x(d.date) + x.bandwidth() / 2)
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

    barGroup.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', LINE_COLOR)
      .attr('stroke-width', 1)
      .attr('d', line)
      .on('mouseenter', () => {
        tooltip.style('display', 'block');
      })
      

    barGroup.selectAll('circle')
      .data(data)
      .join('circle')
      .attr('cx', d => x(d.date) + x.bandwidth() / 2)
      .attr('cy', d => y(d.value))
      .attr('r', 3)
      .attr('fill', DOT_COLOR);

    const tooltip = d3.select(tooltipRef.current);

    
    // X-axis label
    svg.append('text')
      .attr('x', width / 2 + margin.left-20)
      .attr('y', height + margin.top + margin.bottom / 2+10)
      .style('text-anchor', 'middle')
      .style('font-size', '1rem')
      .style('font-family', 'Barlow')
      .style('fill', TEXT_COLOR)
      .text('Date');

    // Y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', margin.left / 2 - 20)
      .attr('x', -height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .style('font-size', '1rem')
      .style('font-family', 'Barlow')
      .style('fill', TEXT_COLOR)
      .text('No. of Attacks');

  }, [data, dimensions]);

  useEffect(() => {
    const handleResize = () => {
    // const containerHeight = svgRef?.current?.parentNode?.clientHeight;
    const containerWidth = svgRef?.current?.parentNode?.clientWidth;

      setDimensions({
        width: containerWidth,
        height: 460
      });
    };

    handleResize(); // Trigger resize handler once on component mount

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to get tick values based on the selected format and data length
  const getTickValues = (data, format) => {
    const dataLength = data?.length;
    switch (format) {
      case '12H':
      case '24H':
      case 'Custom...':
        return data?.map((d, i) => (i % Math.ceil(dataLength / 12) === 0) ? d.date : null)?.filter(Boolean);
      case '1W':
        return data?.map((d, i) => (i % Math.ceil(dataLength / 7) === 0) ? d.date : null)?.filter(Boolean);
      case '1M':
        return data?.map((d, i) => (i % Math.ceil(dataLength / 10) === 0) ? d.date : null)?.filter(Boolean);
      case '1Y':
        return data?.map((d, i) => (i % Math.ceil(dataLength / 13) === 0) ? d.date : null)?.filter(Boolean);

      default:
        return data?.map((d, i) => (i % Math.ceil(dataLength / 12) === 0) ? d.date : null)?.filter(Boolean);
    }
  };

  if (!isLoading && total === 0) {
    return <NoAttacksPage id={id} title={"Attacks Trendline"} />
  }

  return (
    <div id={id} style={{ height: "100%", borderRadius: 10, padding: "2%", backgroundColor: "white", width: "100%", position: 'relative' }}>
      {isLoading ?
        <div id={`loading-${id}`} style={{ height: svgRef?.current?.clientHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PreLoader />
        </div>
        :
        <div id={`bar-chart-${id}`}>
          <Typography id={`${id}-title`} variant='h2' sx={{ fontSize: "1.2rem", textAlign: "center",pb:1 }}>Attacks Trendline</Typography>
          <svg id={`${id}-svg-container`} ref={svgRef} width={dimensions?.width} height={dimensions?.height}></svg>
          <div id={`${id}-tooltip`} ref={tooltipRef} className="tooltip"></div>
        </div>
      }
    </div>
  );
};

export default BarChart;



