import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton } from '@mui/material'
import React from 'react'
import { generatePDF, handleDownloadPDF } from './pdf';
import CloseIcon from '@mui/icons-material/Close';


const CustomDialogBox = ({ isPdfDialogOpen, setPdfDialogOpen, data, selectedHeaders, setSelectedHeaders, setAnchorE }) => {
  
    const hideColumns = ['mode','rule_id'];
  
    const handleToggle = (column) => {
      if (selectedHeaders?.length >= 5 && !selectedHeaders?.includes(column.field)) {
        return;
      }
      setSelectedHeaders(prev => prev?.includes(column.field)
        ? prev?.filter(header => header !== column.field)
        : [...prev, column?.field]
      );
    };

    if(!data?.success){
      return null
    }
  
    return (
      <Dialog open={isPdfDialogOpen} onClose={() => setPdfDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ color:"#1FA7D3", fontSize:"1rem" ,position: 'relative'}}>
          Select Columns for PDF (select max 5 columns for better readability)
          <IconButton onClick={() => setPdfDialogOpen(false)} sx={{ position: 'absolute', top:'0.8rem',right:'1rem',color: '#1FA7D3' }}>
            <CloseIcon sx={{fontSize:"1.2rem",}} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            {data?.headers?.filter(column => !hideColumns.includes(column.field))?.map((column) => (
              <FormControlLabel
                key={column.field}
                control={<Checkbox disabled={selectedHeaders.length >= 5 && !selectedHeaders.includes(column.field)} />}
                label={column.headerName === 'Unique ID'?'Transaction ID':column.headerName}
                checked={selectedHeaders.includes(column.field)}
                onChange={() => handleToggle(column)}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button 
            variant='outlined'
            disabled={selectedHeaders.length === 0}
            onClick={() => {
              generatePDF(data.rows, selectedHeaders,setPdfDialogOpen);
              setAnchorE(null);
              setSelectedHeaders([]);
            }}>
            Export PDF
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default CustomDialogBox;