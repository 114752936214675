import { ACCESS_TOKEN, IS_WAF_ADMIN } from "../../constants/actions";
import COMMON from "../../constants/common";



// Authentication reducer
const initialAuthState = {
    isAnonymous: true,
    access_token: '',
    isWafAdmin: false,
  };
  



export const authActionReducer = (state = initialAuthState, action) => {
    switch (action.type) {
  
      case COMMON.ANONYMOUS:
        return {
          ...state,
          isAnonymous: action.payload,
        };
  
      case ACCESS_TOKEN:
        return {
          ...state,
          access_token: action.payload
        };

      case IS_WAF_ADMIN:
        return {
            ...state,
            isWafAdmin: action.payload
        };
        
       
     
      default:
        return state;
    }
  };
  