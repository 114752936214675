// NoAssignedDomainsPage.jsx

import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    textAlign: 'center',
  },
  message: {
    paddingTop: theme.spacing(2),
    color: '#555',
  },
  logoutButton:{
    color:'#FFFFFF !important',
    marginTop:'1rem !important'
  }
}));

const NoAssignedDomainsPage = ({onLogout}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h2">No domains have been allocated to this client.
    </Typography>
      <Typography variant="body2" className={classes.message}>
        Please contact your administrator for assistance.
      </Typography>
      <Button className={classes.logoutButton} variant='contained' onClick={onLogout}>Log out</Button>
    </Container>
  );
};

export default NoAssignedDomainsPage;
