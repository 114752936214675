
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import CONSTANTS from "../../../constants/constants";
import avatar from "../../../assets/icon/avatar.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

interface UserProfileMenuProps {
  handleProfileMenuOpen:any;
  id:any;
}

const UserProfileMenu = ({handleProfileMenuOpen,id}:UserProfileMenuProps) => {

    const imageSrc = "";
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();


    return (
      <div id={id} style={{ display: "flex", alignItems: "center" }}>
        <span 
          id={`${id}-image-span`}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!imageSrc && keycloak.authenticated ? (
            <Avatar
              id={`${id}-avatar`}
              style={{
                width: "2.2rem",
                height: "2.2rem",
                color: "#4D4D4D",
                backgroundColor: "#FFFFFF",
                border: "1px solid #1FA7D3",
              }}
            />
          ) : imageSrc && keycloak.authenticated ? (
            <img id={`${id}-profile-img`} alt={CONSTANTS.PROFILE} src={avatar} style={{ maxWidth: "100%" }} />
          ) : (
            <Avatar/>
          )}
          <Box>
            <Box
              id={`${id}-welcome-title-box`}
              sx={{
                ml: 1.2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                pt: "2%",
              }}
            >
              <Typography
                id={`${id}-welcome-title`}
                variant='body1'
                sx={{
                  color: "#4D4D4D80",
                  fontWeight: 500,
                  lineHeight: "60%",
                }}
              >
                {t(CONSTANTS.WELCOME)}
              </Typography>
              <Box id={`${id}-username-title-box`} display={"flex"} alignItems="center">
                <Typography
                  id={`${id}-username-title`}
                  variant="h2"
                  sx={{
                    textTransform: "capitalize",
                    lineHeight: "100%",
                    color: "#4D4D4D",
                    fontWeight: 500,
                  }}
                >
                  {sessionStorage.getItem(CONSTANTS.FIRST_NAME)}{" "}
                  {sessionStorage.getItem(CONSTANTS.LAST_NAME)}
                </Typography>
                <Box>
                  <IconButton id={`${id}-arrow-dropdown-icon-btn`} sx={{ padding: 0, alignSelf: "flex-end" }}>
                    <ArrowDropDownIcon
                      id={`${id}-arrow-dropdown-icon`}
                      onClick={handleProfileMenuOpen}
                      sx={{
                        width: "calc(1.8vw + 0.5vmin)", // Responsive icon size
                        height: "calc(1.8vw + 0.5vmin)", // Responsive icon size
                        color: "#4D4D4D",
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </span>
      </div>
    );
  };
  

  export default UserProfileMenu