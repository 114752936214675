import { replace } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import ColorPicker from "../../screen/ColorPicker";
import MainContent from "../../screen/Dashboard/maincontent/MainContent";
import NoPageFound from "../../screen/NoPageFound";
import AllClientsList from "../../screen/WafAdmin/Client/ClientList";
import Users from "../../screen/WafAdmin/User/Users";

const Navigator = () => {
  const { isWafAdmin } = useSelector((state: any) =>  state.authentication );
  
  return (
    <Routes>
      <Route path="/" element={<MainContent />} />
      {/* Render routes only if IS_WAF_ADMIN is true */}
      {isWafAdmin && (
        <>
          <Route path="/clients" element={<AllClientsList />} />
          <Route path="/users" element={<Users />} />
        </>
      )
      }

    <Route path="/color" element={<ColorPicker />} />
      {/* Route for no-page-found */}
      <Route path="/no-page-found" element={<NoPageFound />} />
    </Routes>
  );
};

export default Navigator;
