import CONSTANTS from "../../../constants/constants";
import { pushNotification } from "../../../redux/notification/notificationActions";
import { setButtonLabel, setCustomButton, setStartAndEndDate } from "../../../redux/ui/uiActions";



export const handleButtonGroupClick = (dispatch,label) => {

    // console.log("label ",label)
    // Handle button group click logic here
    if(label.includes('Custom')){
      dispatch(setCustomButton(true))
    }
    dispatch(setButtonLabel(label))
    sessionStorage.setItem('buttonLabel',label)
    
  };

export const handleCustomDate = (dispatch,fromAndToDateTime) =>{
      
    // console.log("fromAndToDateTime ",fromAndToDateTime)
    if(fromAndToDateTime === false){
      dispatch(
        pushNotification({
          isOpen: true,
          message: "Invalid date range. 'From' date and time should be prior to 'To' date and time.",
          type: CONSTANTS.ERROR,
        })
      );
      
    }else{
      dispatch(setStartAndEndDate({
        ...fromAndToDateTime
      }))
      setCustomButton(false)
    }
  }
