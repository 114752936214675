import { Box } from "@mui/material";
import { CountryList } from "./CountryList";
import { VerticalCard } from "./VerticalCard";

interface GradientCardsProps {
  id:string;
  cardsData:any[];
  handlePopupOpen:any;
  countries:any;
}


export const GradientCards = ({id,cardsData,handlePopupOpen,countries}:GradientCardsProps) => {
  

  return (
    <Box id={id} sx={{
      width: { xs: "100%", md: "40%" },
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: "1rem" // Adjust the gap value as needed
    }}>
      <Box id="dashboard-card-container" sx={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        {cardsData.slice(0, 3).map((item, index) => (
          <Box id="dashboard-card" sx={{ width: {xs:"31%",xl:"32%"} }} key={item.id}>
            <VerticalCard
              id={item.id}
              key={item.id}
              isLoading={item.isLoading}
              number={item.number}
              title={item.title}
              imageUrl={item.image}
              onClick={item.title === "Attacks Blocked" ? handlePopupOpen : undefined}
            />
          </Box>
        ))}
      </Box>


        <CountryList id="dashboard-countries-list" countries={countries} />
    </Box>
  )
  };

