import React, { useEffect, useState } from "react";
import { Spinner } from "../../common/Spinner";
import Notification from "../../common/notification";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import WafHeader from "../header/WafHeader";
import './wrapper.css'
import Header from "../header/WafHeader";
import { pushNotification } from "../../../redux/notification/notificationActions";

const useStyles = makeStyles((theme) => ({
 
  content:{
    display:"flex",
    flexDirection:"column",
    overflowY:"hidden",
    width:"100%"
  }
}))


const Wrapper = (props) => {

  const dispatch = useDispatch();
  const { notification } = useSelector((state: any) => state.notification);


  const closeNotification = () => {
    dispatch(
      pushNotification({
        ...notification,
        isOpen: false,
      })
    );
  };


  const classes = useStyles()
  
  return window.location.href.indexOf("signup") <= -1 ?
  (
    <div className={classes.content}> 
      <div className="app">
        <Header/>
        <div className="app-body">
          {props.children}
        </div>
        <Spinner />
        <Notification
          isOpen={notification.isOpen}
          type={notification.type}
          message={notification.message}
          handleClose={closeNotification}
        />
      </div>
      {/* <Footer /> */}
    </div>
  ) : (
   
    <div>
      <Notification
        isOpen={notification.isOpen}
        type={notification.type}
        message={notification.message}
        handleClose={closeNotification}
      />
     
      {props.children}
      
      <Spinner />
    </div>
  );
};

export default Wrapper;