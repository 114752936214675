import { makeStyles } from '@material-ui/core';
import { Button } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  modalBackdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  message:{
    color:'#1FA7D3'
  },
  modalContent: {
    background: 'white',
    padding: 20,
    borderRadius: 5,
    textAlign: 'center',
    zIndex: 1001,
  },
  button: {
  margin:'1rem 1rem 0 1rem !important',
   borderRadius:'0.5rem !important',
   color:'#FFFFFF !important'
  },
}));

const DeletionModal = ({ id,show, onClose, onConfirm, message }) => {
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return (
    <div id={id} className={classes.modalBackdrop}>
      <div id={`modal-content-${id}`} className={classes.modalContent}>
        <p id={`modal-message-${id}`} className={classes.message}>{message}</p>
        <Button id={`modal-confirm-btn-${id}`}  variant='contained' className={classes.button} onClick={onConfirm}>Yes</Button>
        <Button id={`modal-cancel-btn-${id}`} variant='contained' className={classes.button} onClick={onClose}>No</Button>
      </div>
    </div>
  );
};

export default DeletionModal;
