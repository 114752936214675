import { useState, useCallback } from 'react';

const useConfirmationModal = () => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [onConfirmCallback, setOnConfirmCallback] = useState<any>(null);

  const showConfirmationModal = useCallback((onConfirm) => {
    setModalVisible(true);
    setOnConfirmCallback(() => onConfirm);
  }, []);

  const handleConfirm = useCallback(() => {
    if (onConfirmCallback) {
      onConfirmCallback();
    }
    setModalVisible(false);
  }, [onConfirmCallback]);

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  return {
    isModalVisible,
    showConfirmationModal,
    handleConfirm,
    handleModalClose,
  };
};

export default useConfirmationModal;
