import { MENUS_LIST,BUTTON_LABEL, 
        CUSTOM_BUTTON, START_AND_END_DATE_TIME,CARDS_DATA, TIME_LINE_DATA, 
        ATTACKS_BY_SEVERITY, ATTACKS_BY_CATEGORY, ATTACKS_INFORMATION_TABLE, 
        CHART_TABLE_INFO, ATTACKS_BLOCKED_DATA,HEAT_MAP_DATA } from "../../constants/actions"



export const setMenusList = (menusList) => {
    return {
        type: MENUS_LIST,
        payload: menusList
    }
}

export const setButtonLabel = (label) => {
    return {
        type: BUTTON_LABEL,
        payload: label,
    };
};

export const setCustomButton = (customButton) => {

    return {
        type: CUSTOM_BUTTON,
        payload: customButton
    }
}


export const setStartAndEndDate = (startAndEndDate) => {

    return {
        type: START_AND_END_DATE_TIME,
        payload: startAndEndDate
    }
}

export const setCardData = (cardsData) =>{
    return {
        type: CARDS_DATA,
        payload: cardsData
    }
}


export const setTimeLineData = (timeLineData) => {

    return {
        type: TIME_LINE_DATA,
        payload: timeLineData
    }
}

export const setAttacksBySeverity = (attacksBySeverity) => {

    return {
        type: ATTACKS_BY_SEVERITY,
        payload: attacksBySeverity
    }
}

export const setAttacksByCategory = (attacksByCategory) => {

    return {
        type: ATTACKS_BY_CATEGORY,
        payload: attacksByCategory
    }
}

export const setAttacksInformation = (attacksInformation) =>{
    return {
        type: ATTACKS_INFORMATION_TABLE,
        payload: attacksInformation
    }
}

export const setChartTableInfo = (chartTableInfo) =>{
    return {
        type: CHART_TABLE_INFO,
        payload: chartTableInfo
    }
}

export const setAttacksBlockedData = (attacksBlockedData) =>{
    return {
        type: ATTACKS_BLOCKED_DATA,
        payload: attacksBlockedData
    }
}

export const setHeatMapData = (heatMapData)=>{
    return {
        type: HEAT_MAP_DATA,
        payload: heatMapData
    }

}
