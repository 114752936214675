import { PUSH_NOTIFICATION,SNACK_BAR } from "../../constants/actions";


export interface NotificationProps {
    isOpen: boolean;
    type?: "error" | "info" | "success" | "warning";
    message?: string;
    handleClose?: () => void;
  
  }
  const initialNotificationState = {
    notification: {},
    snackbar: {
      open: false,
      from: '',
      to: '',
    },
 
  };
  
  


export const notificationReducer = (state = initialNotificationState, action) => {
    switch (action.type) {
     
  
      case PUSH_NOTIFICATION:
        return { ...state, notification: action.payload };
  
      case SNACK_BAR:
          return {
            ...state,
            snackbar: action.payload,
          };
     
      default:
        return state;
    }
  };
  