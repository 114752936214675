import { PUSH_NOTIFICATION, SNACK_BAR } from "../../constants/actions";

export const pushNotification = (data) => {
    return { type: PUSH_NOTIFICATION, payload: data };
};


export const setSnackbar = (snackbar) => {
    return {
        type: SNACK_BAR,
        payload: snackbar,
    };
};

