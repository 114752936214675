// Sidebar.tsx
import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Link } from "react-router-dom";
import { getMenusApi } from '../../../services/appServices';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  id:string;
}


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width:"12%",
    borderRadius:"10px",
    position: 'fixed',
    // top:105,
    top:0,
    left: 20,
    height: '60vh',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    paddingLeft: '2rem'
  },
  active: {
    backgroundColor: "#1FA7D3 !important",
    color: "#FFFFFF !important",
    '&:hover': {
      backgroundColor: "#6eaae6 !important", // Make sure to match the background color of active state
    },
  },
}));


const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose,id }) => {

  const { menusList } = useSelector((state: any) => state.ui);
  const { isWafAdmin } = useSelector((state: any) => state.authentication);

  const [value, setValue] = useState(0);
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  // console.log("IS_WAF_ADMIN in side ",isAdmin,typeof(isAdmin))

  useEffect(()=>{
    getMenusApi(dispatch)
  },[dispatch])

  const goToUrl = (pathValue) =>{
    isWafAdmin?setValue(pathValue):navigate('/no-page-found')
  }

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setValue(0)
        break;
      case "/clients":
        goToUrl(1)
        break;
      case "/users":
        goToUrl(2)
        break;
      case "/color":
        navigate('/color')
        break;
      default:
        setValue(0);
        navigate('/no-page-found')
        break;
    }
  }, [location.pathname]);
  const classes = useStyles()

  return (
    <Drawer id={id} open={isOpen} onClose={onClose}>
      <List id={`${id}-list-box`}>
        {menusList?.filter(item=>['/','/clients','/users'].includes(item.url)).map((item,index)=>{
          return (
            <ListItem
             id={`${id}-list-item`}
              button
              component={Link}
              to={item.url}
              className={`${classes.listItem} ${value === index ? classes.active : ""}`}
              onClick={() => {
                setValue(index)
                onClose()
              }}
            >
              <ListItemText id={`${id}-list-item-text`} primary={item.label} />
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;

