import React from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';


const MobileMenu = ({anchorEl,handleMobileMenuClose,handleProfileMenuOpen}) => {


  const mobileMenuId = "primary-search-account-menu-mobile";

  const isMobileMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
         sx={{p:0}}
        >
          <AccountCircle />
        </IconButton>
        <Typography id={`${mobileMenuId}-profile-title`} variant='h2'>Profile</Typography>
      </MenuItem>
    </Menu>

  )
}

export default MobileMenu
