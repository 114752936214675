import moment from "moment";
import { ATTACKS_BLOCKED_INFO, ATTACK_TYPES_DATA, DOMAINS_BY_CLIENT, HITS_BY_DOMAIN, LOCATION_END_POINT, LOG_TABLE } from "../constants/endpoints";
import { setDomain, setDomainsByClient } from "../redux/domainData/domainActions";
import { setIsLoadingAttacksBlocked, setIsLoadingLineChart, setLoadingData } from "../redux/loader/loadingActions";
import { setAttacksBlockedData, setAttacksByCategory, setAttacksBySeverity, setAttacksInformation, setCardData, setChartTableInfo, setHeatMapData, setTimeLineData } from "../redux/ui/uiActions";
import { request } from "./request";
import { initialCardsData } from "../screen/Dashboard/utils/cardsData";

const colors = {

    'High':'#1FA7D3',
    'Medium':'#1FA7D3',
    'Low':'#1FA7D3'
    // 'High':'#FF0000',
    // 'Medium':'orange',
    // 'Low':'green'
  };
  
export const domainsByClientApi = async(dispatch)=>{

    const DOMAINS_BY_CLIENT_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${DOMAINS_BY_CLIENT}`;
    try{
        
        const res:any = await request.get(DOMAINS_BY_CLIENT_URL)
        if(res?.success){

            dispatch(setDomainsByClient(res?.data))
            dispatch(setDomain(res?.data[0]))

        }
        else{
            console.log("error")
        }
    }
        
    catch(error){
        console.log("error ",error)
    }
   

}


export const hitsAndAttacksApi = async (dispatch,params) =>{


    const HITS_AND_ATTACKS_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${HITS_BY_DOMAIN}`;
    
    try{
        

        const res:any = await request.get(`${HITS_AND_ATTACKS_URL}${params}`)
        
        const updatedCardsData = initialCardsData?.map((item:any) => {
            return { ...item, number: parseInt(res?.data?.[item.id]),isLoading:false };
          });
        //   console.log("updated data ",updatedCardsData)
          dispatch(setCardData(updatedCardsData))
    
    }
    catch(error){
        console.log("error ",error)
    }

}


const timeIntervals = {
    '12H': 60,    
    '24H': 120,   
    '1W': '',        
    '1M': '',       
    '1Y': '',  
    'Custom...': 120       
 
  };


export const timeLineChartApi = async(dispatch,params,formatKey,label)=>{

    // console.log("label ",label)
    // const interval = formatKey==='minute'? 60:''
    const interval = timeIntervals[label]
    const TIMELINE_ENDPOINT = `${params}&timeline=${formatKey}&interval=${interval}`

    const TIMELINE_CHART_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${ATTACK_TYPES_DATA}${TIMELINE_ENDPOINT}`

    try{

        dispatch(setIsLoadingLineChart(true))
        const res:any = await request.get(`${TIMELINE_CHART_URL}`)
        dispatch(setIsLoadingLineChart(false))

        dispatch(setTimeLineData(res))

    }

    catch(error){
        console.log("error ",error)
    }


}


export const fetchAttackTypesByCategoryApi = async(dispatch,params)=>{
    const ATTACK_TYPES_URL_BY_CATEGORY =`${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${ATTACK_TYPES_DATA}${params}&group_by=attack_category`;

    try{
    
        const response:any = await request.get(ATTACK_TYPES_URL_BY_CATEGORY)
        
        dispatch(setAttacksByCategory(response))

    }

    catch(error){
        console.log("error ",error)
    }


}

const DEFAULT_LABELS = ["High", "Medium", "Low"];

const processResponse = (response) => {
    const countsBySeverity = Object.fromEntries(DEFAULT_LABELS.map(label => [label, 0]));
  
    (response || []).forEach(({ count, label }) => {
      if (countsBySeverity[label] !== undefined) {
        countsBySeverity[label] = count;
      }
    });
  
    return DEFAULT_LABELS.map(label => ({ count: countsBySeverity[label], label }));
  };

export const fetchAttackTypesBySeverityApi = async(dispatch,params)=>{

    const ATTACK_TYPES_URL_BY_SEVERITY =`${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${ATTACK_TYPES_DATA}${params}&group_by=severity`;

    try{
        

        const response:any = await request.get(ATTACK_TYPES_URL_BY_SEVERITY)
        const processedResponse = processResponse(response);

        dispatch(setAttacksBySeverity(processedResponse))
    }

    catch(error){
        console.log("error ",error)
    }
    
}



export const fetchAttackInfoTable = async(dispatch,params,page,rowsPerPage,searchBy,searchItem,isPopUp)=>{
    
    const searchParams =  searchItem?`&${searchBy}=${searchItem}`:''

    const tableParams = `&page_num=${page}&per_page=${rowsPerPage}`

    const LOG_TABLE_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${LOG_TABLE}`;
    
    try{

        dispatch(setLoadingData({
            chart:isPopUp,table:!isPopUp
        }))
        const response: any = await request.get(`${LOG_TABLE_URL}${params}${tableParams}${searchParams}`);
                
        dispatch(setLoadingData({
            chart:false,table:false
        }))

        if(!response.success){
            dispatch(setAttacksInformation(response))
        }
        const headers = response?.data?.headers.map((header) => {
            const [key] = Object.keys(header);
            return {
              headerName: header[key],
              resizable: true,
              field: key,flex:1,
              sortable:true,
              tooltipField: key, // Specify the field for tooltip
              hide: !['Client IP','Status','Event Time','URL','Severity','Unique ID'].includes(header[key]),
              cellStyle: (params) => {
                if (header[key] === 'Status') {
                    const status = params.value;
                    return { color: status === 'Blocked' ? 'red' : status === 'Allowed' ? 'green' : 'black' };
                }
                else if (header[key] === 'Severity') {
                    const severity = params.value;
                    return { color: colors[severity] || 'black' };
                  }
               
                },
            
            };
            
          });

        const tableData ={
            headers:headers,
            rows: response?.data?.rows,
            total: response?.data?.total,
            totalPages:response?.data?.total_pages,
            hasNext:response?.data?.has_next,
            hasPrev:response?.data?.has_prev,
            success:response?.success
        }
        if(!isPopUp){
            dispatch(setAttacksInformation(tableData))
        }
        dispatch(setChartTableInfo(tableData))

    }

    catch(error){

        console.log("error ",error)
    }
    
}



export const fetchAttackBlockedPopUp = async(dispatch,params,page,rowsPerPage)=>{
    
    const tableParams = `&page_num=${page}&per_page=${rowsPerPage}`

    const ATTACK_BLOCKED_INFO_URL =`${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${ATTACKS_BLOCKED_INFO}${params}`

    try{

        dispatch(setIsLoadingAttacksBlocked(true))
        const res:any = await request.get(`${ATTACK_BLOCKED_INFO_URL}${tableParams}`)
        
        dispatch(setIsLoadingAttacksBlocked(false))

        const headers = res?.data?.headers.map((header) => {
            const [key] = Object.keys(header);
            return {
              headerName: header[key],
              field: key,
              flex:1,
              resizable: true,
              tooltipField: key, // Specify the field for tooltip
              cellStyle: (params) => {
                if (header[key] === 'Status') {
                    const status = params.value;
                    return { color: status === 'Blocked' ? 'red' : status === 'Allowed' ? 'green' : 'black' };
                }
            },
            
            };
          });  

        const data ={
            headers:headers,
            rows: res?.data?.rows,
            total: res?.data?.total,
            totalPages:res?.data?.total_pages,
            hasNext:res?.data?.has_next,
            hasPrev:res?.data?.has_prev
        }
          
        dispatch(setAttacksBlockedData(data))
    }

    catch(error){
        console.log("error ",error)
    }
    
}

  

export const fetchLocationData = async (dispatch,traffic,params) => {

    const LOCATION_PARAMS = `${params}&traffic=${traffic}`
    try {
      const LOCATION_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}${LOCATION_END_POINT}${LOCATION_PARAMS}`;
      const jsonData: any = await request.get(LOCATION_URL);

      dispatch(setHeatMapData(jsonData));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };