import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { getAllPaginatedClients } from "./clientServices"



const AutoCompleteClients = ({handleChangeClient,clientName}) =>{

    const [clientsList,setClientsList] = useState([])

    useEffect(()=>{
    
        handleGetAllClients()

    },[clientName])



    const handleGetAllClients = async()=>{
        const response:any = await getAllPaginatedClients(1,10,clientName)
        // console.log("respo",response)
        setClientsList(response.data)
    }

    const handleChange =(event,newValue)=>{
        console.log("handle change",newValue)
        handleChangeClient(newValue)
    }

    return (
        
        <Autocomplete
            sx={{width:"100%"}}
            value={clientName}
            onChange={(event, newValue) => handleChange(event,newValue)}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={clientsList?.map((option:any) => option?.client_name)}
            renderInput={(params) => (
            <TextField
                
                {...params}
                label="Select Client Name"
                InputProps={{
                ...params.InputProps,
                type: 'search',
                }}
            />
            )}
        />








    )

}

export default AutoCompleteClients