import ClickIcon from '../../../assets/svg/Click.svg';
import AttacksIcon from '../../../assets/svg/Attacks.svg';
import AttackPercentIcon from '../../../assets/svg/Percent.svg';
import AttacksBlocked from '../../../assets/svg/blocked_icon.svg';

export const initialCardsData = [
    {
      id: 'total_hits',
      image: ClickIcon,
      number: 0,
      title: 'Total Hits',
      isLoading: true,  // Initial loading state
  
    },
    {
      id: 'total_attacks_blocked',
      image: AttacksBlocked,
      number:  0,
      title: 'Attacks Blocked',
      isLoading: true,  // Initial loading state
  
    },
    {
      id: 'attack_percentage',
      image: AttackPercentIcon,
      number:  0,
      title: 'Attack Percentage',
      isLoading: true,  // Initial loading state
  
    },
  ];

