import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  boxContainer:{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    width:"90%",padding:'1rem'
  },
  textField: {
    margin: theme.spacing(1),
    width: '15ch',
  },
  button: {
    margin: theme.spacing(1),
    color:"#FFFFFF !important"
  },
}));

const ColorPicker = () => {
  const classes = useStyles();
  const [color, setColor] = useState('#000000'); // Initial color

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleApplyColor = () => {
    // Implement your logic to apply color to the component
    console.log('Color applied:', color);
  };

  return (
    <Box className={classes.boxContainer}>
      <TextField
        label="Color"
        variant="outlined"
        className={classes.textField}
        value={color}
        onChange={handleColorChange}
      />

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleApplyColor}
      >
        Apply Color
      </Button>
    </Box>
  );
};

export default ColorPicker;
