export const convertToIST = (gmtTimeString) => {
    const gmtDate = new Date(gmtTimeString);
    let ISTString =  gmtDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', 
    
        }).replace(/\//g, '-');

        // Extracting AM/PM and converting to uppercase
        const ampm = ISTString.slice(-2).toUpperCase();
        ISTString = ISTString.slice(0, -2) + ampm;

        return ISTString;


    };
