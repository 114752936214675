import React, {useState } from "react";
import {
  Box,
  Button,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CONSTANTS from "../../../constants/constants";
import { addOrEditOrDeleteUser } from "./userService";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { CustomButton, CustomFormLabel, PopUpHeading } from "../reuseStyles";
import { pushNotification } from "../../../redux/notification/notificationActions";

export const useStyles = makeStyles((theme) => ({
  screenHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#4B6D8F",
    color: "#FFFFFF",
    paddingLeft:"0.8rem",

    [theme.breakpoints.up('xl')]: {
      padding:"1rem",
    },
    
  },
  lmsSubmitBtn: {
    backgroundColor: "#4B6D8F !important",
    borderRadius: "35px !important",
    width: "137px",
  },
  lmsDatePicker: {
    height: "39px",
    // width: "300px",
  },
  fieldLabel: {
    marginBottom: "10px",
  },
}));

const CreateUser = (props: any) => {
  // const { show, setShow,clientsList,roles,groups} = props;
  const { show, setShow} = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [initalStateValues, setInitalStateValues] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailId: "",
    clientName:"",
    department: "NA",
    // groups: [],
    // roles: [],
    groups: ["ts-user"],
    roles: ["waf-user"],
  });

  const formOne = useFormik({
    initialValues: initalStateValues,
    validationSchema: Yup.object({
      userName: Yup.string()
        .required("User Name is Requried")
        .matches(/^[a-zA-Z0-9]+$/, 'Username must contain only letters and numbers')
        .max(10, "Maximum length is 10 Characters")
        .min(4, "Minimum length is 4 Characters")
        .trim(),
      firstName: Yup.string().required("First Name is Requried"),
      lastName: Yup.string().required("Last Name is Requried"),
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
        .length(10)
        .required("Mobile Number is Requried"),
      emailId: Yup.string()
        .email("Please Enter a Valid Email Id")
        .required("Email Id is Requried"),
      clientName: Yup.string(),
      department: Yup.string(),
      groups: Yup.array(),
      // roles: Yup.array().min(1, "role is Required"),
      roles: Yup.array()

    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    postFormValues(values);
  };

  // const buildRoles = (roles) => {
  //   const rolesArray = roles?.map((item) => {
  //     return item.roleName;
  //   });
  //   return rolesArray;
  // };

  // const buildGroups = (groups) => {
  //   const groupsArray = groups?.map((item) => {
  //     return item.name;
  //   });
  //   let finalGroupArray = [
  //     ...groupsArray,
  //     "ts-user",
  //     "camunda-admin",
  //     "awgment-modeler-menus",
  //   ];
  //   return finalGroupArray;
  // };


  // const buildClient = (clientsList) => {
  //   const groupsArray = clientsList?.map((item) => {
  //     return item.client_name;
  //   });
    
  //   return groupsArray
  // };

  const formPayload = (values: any) => ({
    userName: values.userName,
    firstName: values.firstName,
    lastName: values.lastName,
    mobileNumber: values.mobileNumber,
    emailId: values.emailId,
    clientName:values.clientName,
    department: values.department,
    groups:values.groups,
    roles:values.roles
    // groups: buildGroups(values.groups),
    // roles: buildRoles(values.roles),
  });

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response = await addOrEditOrDeleteUser("add", null, data);

    
    if (response?.success) {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  return (
    <Box 
        
        padding={"2%"}>
      <form 
        onSubmit={formOne.handleSubmit}>
      <Box     
           
          sx={{
            position:"absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor:"#FFFFFF",
            overflow:"auto",
            // zIndex:1,
            "@media (max-width:600px)":{
              width:"18rem",height:"20rem"
            },
            "@media (min-width:601px)":{
              width:"24rem",height:"22rem"
            },
            "@media (min-width:768px)":{
              width:"30rem",height:"25rem"
            },
            "@media (min-width:1024px)":{
              width:"38rem",height:"26rem"
            },
            "@media (min-width:1366px)":{
              width:"44rem",height:"28rem"
            },
            "@media (min-width:2560px)":{
              width:"65rem",height:"30rem"
            },
            "@media (min-width:3200px)":{
              width:"65rem",height:"30rem"
            },

        }}>
           
          <Box className={classes.screenHeadingContainer}>
          <PopUpHeading variant='h4'>Create User</PopUpHeading>
            <Box>
              <Button
                sx={{ color: "#FFFF" }}
                onClick={() => {
                  setShow(false);
                }}
              >
                <CloseOutlinedIcon sx={{fontSize:"1.2rem"}}/>
              </Button>
            </Box>
          </Box>
          <Box 
          
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={"2%"}
              gap={"1rem"}
              p={"0.8rem"}
             
            >
              <Box display={"flex"} flexDirection={"column"}>
              <CustomFormLabel>User Name<span style={{fontSize:"1rem",color:"red"}}> *</span></CustomFormLabel>        
                <TextField
                  id="User-Name"
                  name="userName"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.userName}
                  helperText={
                    formOne.touched.userName && formOne.errors.userName
                  }
                  error={
                    formOne.touched.userName && Boolean(formOne.errors.userName)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
              <CustomFormLabel>First Name<span style={{fontSize:"1rem",color:"red"}}> *</span></CustomFormLabel>        
                <TextField
                  id="first-Name"
                  name="firstName"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.firstName}
                  helperText={
                    formOne.touched.firstName && formOne.errors.firstName
                  }
                  error={
                    formOne.touched.firstName &&
                    Boolean(formOne.errors.firstName)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
              <CustomFormLabel>Last Name<span style={{fontSize:"1rem",color:"red"}}> *</span></CustomFormLabel>        
                <TextField
                  id="last-Name"
                  name="lastName"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.lastName}
                  helperText={
                    formOne.touched.lastName && formOne.errors.lastName
                  }
                  error={
                    formOne.touched.lastName && Boolean(formOne.errors.lastName)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
              <CustomFormLabel>Mobile Number<span style={{fontSize:"1rem",color:"red"}}> *</span></CustomFormLabel>        
                <TextField
                  id="mobile-Number"
                  name="mobileNumber"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.mobileNumber}
                  helperText={
                    formOne.touched.mobileNumber && formOne.errors.mobileNumber
                  }
                  error={
                    formOne.touched.mobileNumber &&
                    Boolean(formOne.errors.mobileNumber)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
              <CustomFormLabel>Email<span style={{fontSize:"1rem",color:"red"}}> *</span></CustomFormLabel>        
                <TextField
                  
                  id="email-Id"
                  name="emailId"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.emailId}
                  helperText={formOne.touched.emailId && formOne.errors.emailId}
                  error={
                    formOne.touched.emailId && Boolean(formOne.errors.emailId)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>
            {/* <Box display={"flex"} flexDirection={"column"}>
              <label className={classes.fieldLabel}>Clients List <span style={{color:"red"}}>*</span></label>
                <Select
                  sx={{ width: "600px",p:0.2 }}
                  size="small"
                  name="clientId"
                  value={formOne.values.clientId}
                  onChange={(event) => formOne.setFieldValue('clientId', event.target.value)}
                  onBlur={formOne.handleBlur}
                >
                  {clientsList?.map((item)=>{
                    return <MenuItem key={item.client_id} value={item.client_id}>{item.client_name}</MenuItem>
                  })}
                </Select>
              </Box> */}
            
              

              {/* <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>Department</label>
                <TextField
                  id="Department"
                  name="department"
                  size="small"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.department}
                  helperText={
                    formOne.touched.department && formOne.errors.department
                  }
                  error={
                    formOne.touched.department &&
                    Boolean(formOne.errors.department)
                  }
                  sx={{ width: "600px" }}
                />
              </Box> */}

              {/* <Box display={"flex"} flexDirection={"column"}>
                <label className={classes.fieldLabel}>Groups</label>

                <Autocomplete
                  disablePortal
                  id="groups"
                  multiple
                  options={groups}
                  getOptionLabel={(option: any) => option?.name || ""}
                  onChange={(e, value) =>
                    formOne.setFieldValue("groups", value)
                  }
                  onBlur={formOne.handleBlur}
                  value={formOne.values.groups}
                  size="small"
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      value=""
                      {...params}
                      error={
                        formOne.touched.groups && Boolean(formOne.errors.groups)
                      }
                      helperText={
                        formOne.touched.groups && formOne.errors.groups
                      }
                    />
                  )}
                />
              </Box>   */}
              {/* <Box display={"flex"} flexDirection={"column"}>
              <CustomFormLabel>Roles<span style={{fontSize:"1rem",color:"red"}}> *</span></CustomFormLabel>        
                <Autocomplete
                  disablePortal
                  id="roles"
                  multiple
                  options={roles}
                  getOptionLabel={(option: any) => option?.roleName || ""}
                  onChange={(e, value) => formOne.setFieldValue("roles", value)}
                  onBlur={formOne.handleBlur}
                  value={formOne.values.roles}
                  size="small"
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={formOne.values.roles}
                      error={
                        formOne.touched.groups && Boolean(formOne.errors.roles)
                      }
                      helperText={
                        formOne.touched.groups && formOne.errors.roles
                      }
                    />
                  )}
                />
              </Box> */}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Box display={"flex"} flexDirection={"column"} mb={"2rem"}>
                <CustomButton
                  variant="contained"
                  type="submit"
                  sx={{
                    
                    textTransform:"none"}}
                  disabled={formOne.isSubmitting}
                >
                  Add User
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CreateUser;
