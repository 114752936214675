export const TEMPLATE_UPLOAD = '/dms/v1/documents?documentTypeId=';
export const TEMPLATE_DOWNLOAD = '/dms/v1/documents';
export const DELETE_DOCUMENT = '/dms/v1/documents?id='
export const GENERATE_OTP = '';
export const VERIFY_OTP = '';
export const KEYCLOAK_TOKEN = 'auth/realms/cybersophy/protocol/openid-connect/token';

export const TILE_DATA = 'api/ociso/1.0.0/dashboard/tiles'
export const QUESTIONNAIRE = 'api/ociso/1.0.0/surveys/security-questionnaire'
export const QUESTIONNAIRE_RESPONSE = 'api/ociso/1.0.0/surveys/response-questionnaire-data'
export const UPLOAD_SURVEY_QUESTIONS = 'api/ociso/1.0.0/surveys/upload-security-questionnaire'
export const TILE_VALUES_COUNT = 'api/ociso/1.0.0/assets/count'
export const SURVEY_QUESTIONS_DETAILS = "api/ociso/1.0.0/surveys/survey-questions-details"
export const GET_SURVEY_DETAILS = "surveys/get-survey-questions"
export const LOG_TABLE = 'attacks-information'
export const HITS_BY_DOMAIN = 'hits-and-attacks'
export const DOMAINS_BY_CLIENT = 'client-domains'
export const ATTACK_TYPES_DATA = 'charts'
export const PIE_CHART_TABLE = 'attacks-info/chart'
export const LOCATION_END_POINT = 'location'
export const EULA_SET_ENDPOINT='eula'
export const EULA_CHECK_ENDPOINT='check/eula'




export const USER_PROCESS_DEFINITION_KEY = "Process_844844770998710300";
export const GROUP_PROCESS_DEFINITION_KEY = "Process_tyoovr6";
export const ACTION_EDIT = "edit";
export const ACTION_DELETE = "delete";

export const TASKMANAGEMENT_USER_ENDPOINT = "task-management/v1/user-info";
export const TASKMANAGEMENT_USERS_ENDPOINT = "task-management/v1/users";
export const USERS_ENDPOINT = "accounts/v1/users";
export const USERS_GROUPS = "accounts/v1/groups";
export const USERS_ROLES = "accounts/v1/keycloak/roles";
export const USER_PREFERENCE = "accounts/v1/users/preferences";
export const MENUS = "accounts/v1/roles/menu"

export const START_PROCESS = "workflow/v1/process/start";


export const CLIENT_ACCOUNT = "client-account";
export const CLIENT_LIST = "clients-list"
export const UPDATE_CLIENT="update-client"
export const DELETE_CLIENT ="delete-client"
export const ADD_URL ="add-url"
export const MAP_CLIENT_USER ="map-client-user"
export const GET_CLIENT_URLS = "get-urls"

export const DELETE_USER_FROM_CLIENT = "delete-client-user"
export const DELETE_URL_FROM_CLIENT = "delete-client-url"
export const UPDATE_URL_IN_CLIENT = "edit-client-url"
export const SEND_REPORT = "waf-report"
export const ATTACKS_BLOCKED_INFO ='attacks-blocked-info'