import { styled } from "@mui/material/styles";
import Switch, { switchClasses } from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUrlInClient } from "../../WafAdmin/Client/clientServices";
import { Avatar, Chip, Skeleton } from "@mui/material";
 
export const SwitchTextTrack = styled(Switch)(({theme})=>({
  width: 60,
  height: 36,
  padding: 4,
  [`& .${switchClasses.switchBase}`]: {
    padding: 8,
    "&.Mui-checked": {
      color: "#3BB1D8",
      "&.Mui-switchBase": {
        backgroundColor: "linear-gradient(180deg, #3BB2D9 0%, #268CAD 100%)",
        boxShadow: "0px 3px 6px 0px #0000000F",
      },
    },
    "&.Mui-focusVisible": {
      boxShadow: "0 0 4px 2px rgba(33, 203, 243, 0.5)",
    },
  },
  [`& .${switchClasses.thumb}`]: {
    width: 18,
    height: 18,
    background: "linear-gradient(157.66deg, #C9C9C9 -15.38%, #6A6A6A 85.44%)",
    "&.Mui-checked": {
      background: "linear-gradient(180deg, #3BB2D9 0%, #268CAD 100%)",
    },
  },
  [`& .${switchClasses.track}`]: {
    backgroundColor: "#ffffff !important",
    opacity: "1 !important",
    borderRadius: 18,
    position: "relative",
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      fontSize: "0.8rem",
      fontWeight: 500,
      color: "#787878",
    },
    "&:before": {
      content: '"ON"',
      left: 6,
      opacity: 0,
      color: "#3BB1D8",
      
    },
    "&:after": {
      content: '"OFF"',
      right: 1,
      color: "#787878",
    },
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: "#185a9d",
      transform: "translateX(24px)",
      "&:hover": {
        backgroundColor: "rgba(24,90,257,0.08)",
      },
    },
    [`& .${switchClasses.thumb}`]: {
      background: "linear-gradient(180deg, #3BB2D9 0%, #268CAD 100%)",
    },
    [`& + .${switchClasses.track}`]: {
      "&:before": {
        opacity: 1,
        color: "#3BB1D8",
      },
      "&:after": {
        opacity: 0,
        color: "#787878",
      },
    },
    [`&.${switchClasses.disabled}`]: {
      color: theme.palette.action.disabled,
      '&.Mui-switchBase': {
        backgroundColor: theme.palette.action.disabledBackground,
        boxShadow: 'none',
      },
    },

    
  },
}));
 
const ModeSwitch = ({id,checkedValue}) => {

  const { isWafAdmin } = useSelector((state: any) =>  state.authentication );

  // console.log("checkedValue ",checkedValue)

  const [selected,setSelected] = useState(checkedValue)

  // const isDisabled = false

  const dispatch =useDispatch()

  useEffect(() => {
    // Update the local state when the prop changes
    setSelected(checkedValue);
  }, [checkedValue]);

  const handleSwitchChange = () => {
    setSelected((prevSelected) => {
      // Update the local state immediately to reflect the UI change
      const newSelected = !prevSelected;
      // Pass the original checked value to the parent component
      editUrlInClient(id, { "mode": newSelected }, dispatch);
      const storedData = JSON.parse(sessionStorage.getItem('domain') || '{}');

      // storedData.mode = `${newSelected}`; // Replace false with the desired value for "mode"
      const data = {...storedData,mode:`${newSelected}`}

      sessionStorage.setItem('domain', JSON.stringify(data));
      
      return newSelected;
    });
  };


  if(checkedValue === undefined){
    return <Skeleton variant="rectangular" sx={{borderRadius:'1rem'}} width={'9rem'} height={'2rem'} />
  }

  if (!isWafAdmin){
    return <Chip  
    sx={{
      display:"flex",fontSize:"0.9rem",
      backgroundColor:"#FFFFFF",color:checkedValue?'red':'#ff8503',
      flexDirection:"row-reverse",alignItems:"center"
    }}

    //  avatar={<Avatar sx={{backgroundColor:"#dcdfe3 !important",color:checkedValue?'#1FA7D3 !important':'#696969 !important',
    // }}>{checkedValue?'ON':'OFF'}</Avatar>} 
    label={`${checkedValue?'Block Mode':'Transparent Mode'}`}/>

  }

  return (
    <FormGroup title={!isWafAdmin?"disabled":"change the mode here"} id={id}>
      <div id="mode-switch-container" style={{ display: "flex", alignItems: "center" }}>
        <span id="mode-switch-label" style={{fontSize: "0.9rem",fontWeight: 400, fontFamily: "Barlow",color:"#000"}}>Block Mode</span>
        <SwitchTextTrack 
          id="mode-switch-switch"
          onClick={!isWafAdmin ? undefined : handleSwitchChange} 
          checked={selected} disableRipple
          />
        <sup id="mode-switch-tooltip" title={!isWafAdmin?"disabled":"Block mode ON indicates that the domain is protected"}><InfoIcon style={{ marginLeft: "0px",fontSize: "1.2rem",color: "#8D8D8D" }} /></sup>
      </div>
    </FormGroup>
  );
};
 
export default ModeSwitch;