import moment from "moment";
import { BUTTON_LABEL, MENUS_LIST,CUSTOM_BUTTON,START_AND_END_DATE_TIME, CARDS_DATA,TIME_LINE_DATA, ATTACKS_BY_SEVERITY, ATTACKS_BY_CATEGORY, ATTACKS_INFORMATION_TABLE, CHART_TABLE_INFO, ATTACKS_BLOCKED_DATA, HEAT_MAP_DATA } from "../../constants/actions";
import { initialCardsData } from "../../screen/Dashboard/utils/cardsData";


const initialUiState = {

    menusList: [],
    buttonLabel: '12H-12-h',
    customButton: false,
    startAndEndDate:{
      from: moment().format('YYYY-MM-DD HH:mm:ss'),
      to: moment().add(1,'s').format('YYYY-MM-DD HH:mm:ss')
    },
    cardsData:[...initialCardsData],
    heatMapData:{},
    timeLineData:{},
    attacksBySeverity:[],
    attacksByCategory:[],
    attacksInformation:{
      headers: [],
      rows: [],
      total: 0,
      totalPages:0,
      hasNext:true,
      hasPrev:false
    },
    chartTableInfo:{
      headers: [],
      rows: [],
      total: 0,
      totalPages:0,
      hasNext:true,
      hasPrev:false
    },

    attacksBlockedData:{
      headers: [],
      rows: [],
      total: 0,
      totalPages:0,
      hasNext:true,
      hasPrev:false
    },
  
  };
  



export const loadingUiReducer = (state = initialUiState, action) => {
  switch (action.type) {

    case MENUS_LIST :
      return {
        ...state,
        menusList: action.payload,
      };

    case BUTTON_LABEL:  
      return {
        ...state,
        buttonLabel:action.payload
      }
      
    case CUSTOM_BUTTON:  
      return {
        ...state,
        customButton:action.payload
      }
    case START_AND_END_DATE_TIME:  
      return {
        ...state,
        startAndEndDate:action.payload
      }

    case CARDS_DATA:  
      return {
        ...state,
        cardsData:action.payload
      }
    case TIME_LINE_DATA:  
      return {
        ...state,
        timeLineData:action.payload
      }
    case ATTACKS_BY_SEVERITY:  
      return {
        ...state,
        attacksBySeverity:action.payload
      }

    case ATTACKS_BY_CATEGORY:  
      return {
        ...state,
        attacksByCategory:action.payload
      }
    case ATTACKS_INFORMATION_TABLE:  
      return {
        ...state,
        attacksInformation:action.payload
      }

    case CHART_TABLE_INFO:  
      return {
        ...state,
        chartTableInfo:action.payload
      }

    case ATTACKS_BLOCKED_DATA:  
      return {
        ...state,
        attacksBlockedData:action.payload
      }
    case HEAT_MAP_DATA:  
      return {
        ...state,
        heatMapData:action.payload
      }
    default:
      return state;
  }
};
