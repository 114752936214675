import { LOADER,IS_LOADING_DATA, LINE_CHART_LOADER,IS_LOADING_ATTACKS_BLOCKED } from "../../constants/actions";


const initialLoadingState = {

    isLoading: false,
    loadingData:{
      table:false,chart:false
    },
    isLoadingLineChart:false,
    isLoadindAttacksBlockedPopup:false
    
  };
  



export const loadingActionReducer = (state = initialLoadingState, action) => {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case IS_LOADING_DATA:
        return {
          ...state,
          loadingData: action.payload,
        };
    case LINE_CHART_LOADER:
      return {
        ...state,
        isLoadingLineChart: action.payload,
      };
    case IS_LOADING_ATTACKS_BLOCKED:
        return {
          ...state,
          isLoadindAttacksBlockedPopup: action.payload,
        };
        
      
    default:
      return state;
  }
};
