import { IconButton, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../../redux/notification/notificationActions';
import { Close} from "@material-ui/icons";

interface SnackbarComponentProps {
  id:string;
}

const SnackbarComponent = ({id}:SnackbarComponentProps) => {
  const { snackbar} = useSelector((state: any) => state.notification);

  const dispatch = useDispatch()
  
  return (
      <Snackbar
          id={id}
          anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
          open={snackbar.open}
          onClose={()=>dispatch(setSnackbar({open: false,
            from: '',
            to: ''}))}
          message={`${snackbar.from} - ${snackbar.to}`}
          key={'snackbar'}
          action={<IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={()=>dispatch(setSnackbar({open: false,
              from: '',
              to: ''}))}
          >
            <Close fontSize="small" />
          </IconButton>}
        />
  )
}

export default SnackbarComponent
