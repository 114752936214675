import { ACCESS_TOKEN, IS_WAF_ADMIN, LOADER } from "../../constants/actions";




export const setAnonymousUser = (data) => {
    return {
        type: LOADER,
        payload: data,
    };
};


export const setAccessToken = (token) => {
    return {
        type: ACCESS_TOKEN,
        payload: token
    }
}

export const setIsWafAdmin = (isAdmin) => {
    return {
        type: IS_WAF_ADMIN,
        payload: isAdmin
    }
}
