import CONSTANTS from "../../../constants/constants";
import { CLIENT_ACCOUNT,

    CLIENT_LIST,UPDATE_CLIENT,

    DELETE_CLIENT,ADD_URL,MAP_CLIENT_USER, GET_CLIENT_URLS, DELETE_USER_FROM_CLIENT,

    DELETE_URL_FROM_CLIENT,

    UPDATE_URL_IN_CLIENT,
    SEND_REPORT

} from "../../../constants/endpoints";
import { pushNotification } from "../../../redux/notification/notificationActions";

import { request } from "../../../services/request";
const URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}`

export const getAllPaginatedClients = async (page,rowsPerPage,searchTerm) => {

    try{

        const pageSize = rowsPerPage? `&per_page=${rowsPerPage}` : "";
        const search = searchTerm ? `&search=${searchTerm}` : "";

        const response:any = await request.get(`${URL}${CLIENT_LIST}?page_num=${page}${pageSize}${search}`)
    
        return response
    }

    catch(error){

        return error

    }   

}

export const addOrEditOrDeleteClient = async(

    action: "add-client" | "update" | "delete" | "add-url",

    clientId?: string | null,

    data?: any | null,

)=>{


    if(action === "delete"){

        const response:any = await request.delete(`${URL}${DELETE_CLIENT}?client_id=${clientId}`)

        return response

    }

    if(action === "update"){
        const response:any = await request.put(`${URL}${UPDATE_CLIENT}?client_id=${clientId}`,data)
        return response

    }

    if(action === "add-url"){

        // console.log("urldata",data)

        const response:any = await request.post(`${URL}${ADD_URL}`,data)

        return response

    }
    if(action === "add-client"){

        const response:any = await request.post(`${URL}${CLIENT_ACCOUNT}`,data)

        return response

    }

}

export const getAllClientUrls = async (clientId)=>{
    const search = clientId ? `?search=${clientId}` : "";
    try{

        const response:any = await request.get(`${URL}${GET_CLIENT_URLS}${search}`)

        return response.data

    }

    catch(error){

        return
    }

}
export const deleteUserFromClient = async (userId)=>{
    const response:any =  await request.delete(`${URL}${DELETE_USER_FROM_CLIENT}?user_id=${userId}`)

    return response

}
export const deleteUrlFromClient = async (clientDomainId)=>{
   const response:any =  await request.delete(`${URL}${DELETE_URL_FROM_CLIENT}?clientDomainId=${clientDomainId}`)

   
    return response

}
export const editUrlInClient =async (clientDomainId,data,dispatch)=>{

    const response:any = await request.put(`${URL}${UPDATE_URL_IN_CLIENT}?clientDomainId=${clientDomainId}`,data)
    
    if (response?.success) {
        dispatch(
        pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
        })
        );
    } else {
        
        dispatch(
        pushNotification({
            isOpen: true,
            message: response.error,
            type: CONSTANTS.ERROR,
        })
        );
    }

}
export const mapUserAndClient =async (data)=>{

    const response:any = await request.post(`${URL}${MAP_CLIENT_USER}`,data)
    return response

}


export const sendReportApi = async(send_data) =>{

    const response:any = await request.post(`${URL}${SEND_REPORT}`,send_data)
    return response

}