// ButtonGroupComponent.tsx
import React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { handleButtonGroupClick } from '../utils/handlers';
import { buttons } from '../utils/initialButtons';


interface ButtonGroupComponentProps {
  id?:string;
  timeLabel?:string
}


const ButtonGroupComponent: React.FC<ButtonGroupComponentProps> = ({id,timeLabel}) => {

  const dispatch = useDispatch()

  return (
    <Box id={id}>
        {buttons.map((button) => {
          const isActive = ((timeLabel??button.label) === button.label)
         
          const time = button.label.split('-')[0]
          return (
            <Button 
               id="time-button"
               title={button.tooltip}
                sx={{
                  borderRadius:8,
                  // border: isActive?'1px solid #BEBEBE':borderWhenScrolled,
                  border: isActive?'1px solid #BEBEBE':'',
                  p:"0.5% 4% 0.5% 4%",
                  color:isActive?"#FFFFFF":'#696969',
                  backgroundColor:isActive?"#1FA7D3":'',
                  // textTransform:"none",
                  '&:hover':{
                    backgroundColor:"#9fd7f5",
                    color:'#FFFFFF',
                    border:'1px solid #ffffff'
                    }
                }}
                key={button.key} onClick={() => handleButtonGroupClick(dispatch,button.label)}>
                {time.includes('Custom')?<DateRangeIcon/>:time}
            </Button>
            )
        }
        )}
    </Box>

  );
};

export default ButtonGroupComponent;
