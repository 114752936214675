
import React from 'react'
import { Close } from '@material-ui/icons'
import { Drawer, Box, Typography, IconButton, Tabs, Tab, Grid } from '@mui/material'
import AllowedImg from '../../../assets/svg/allowed.svg';
import BlockedImg from '../../../assets/svg/blocked.svg';
import { useSelector } from 'react-redux';
import { convertToIST } from '../utils/convertTime';


const renderFieldWithNA = (label, value) => (
  <Typography variant="body1">
      <span style={{ color: '#1FA7D3' }}>{label}:</span><br/>
      {value ? value : "N/A"}
  </Typography>
);

const MoreInfo = ({ drawerOpen, closeDrawer, 
    selectedRowData, selectedTabIndex, setSelectedTabIndex }) => {
    const selectedLog = selectedRowData?.audit_logs[selectedTabIndex];
    const { isWafAdmin } = useSelector((state: any) =>  state.authentication );

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={closeDrawer}
        >
            <Box sx={{ overflowY:"scroll",width: "100%", maxWidth: '45rem' }}>
                <Box sx={{
                    width: "100%",
                    backgroundColor: "#E5EBF1",
                    display: "flex",
                    alignItems: "center",
                    p:'1rem'
                }}>
                    <Typography variant="body2" component="span">
                        URL:
                    </Typography>
                    <Typography
                        title={selectedRowData?.url}
                        variant="body2"
                        sx={{
                            color: "#696969",
                            width:'100%',
                            maxWidth: "calc(100% - 50px)", // Adjusted maxWidth
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            border: '1px solid #C6C6C6',
                            padding: "0.5rem",
                            backgroundColor: "#f0f1f2",
                            marginLeft: "0.5rem",
                            borderRadius: "2rem",
                            flexGrow: 1
                        }}
                    >
                        {selectedRowData?.url}
                    </Typography>
                   
                    <IconButton onClick={closeDrawer} sx={{ ml: 1 }}>
                        <Close style={{ fontSize: "1.3rem", color: '#1FA7D3' }} />
                    </IconButton>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "1rem 1.8rem 0" }}>
              
                <Box sx={{width:"100%",display:"flex",alignItems:"center"}}>
                      {selectedRowData?.status === "Allowed"?<img style={{height:"5rem",width:"auto"}} src={AllowedImg}/>:
                      <img style={{width:"auto",height:"5rem"}} src={BlockedImg}/>}
                    </Box>
                <Typography variant="h2" sx={{ paddingBottom: 2 }}>Request/Response Analysis:</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        {renderFieldWithNA("Event Time", convertToIST(selectedRowData?.event_time))}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {renderFieldWithNA("Client IP", selectedRowData?.client_ip)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {renderFieldWithNA("Status", selectedRowData?.status)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {renderFieldWithNA("Severity", selectedRowData?.severity)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {renderFieldWithNA("Request Method", selectedRowData?.request_method)}
                    </Grid>
                        
                    <Grid item xs={12} sm={6} md={4}>
                        {renderFieldWithNA('Transaction ID', selectedRowData?.unique_id)}
                    </Grid>
                
                    
                </Grid>
            </Box>


            <Box sx={{ overflowY: 'auto', padding: '1rem',}}>
                <Box>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(e, newValue) => setSelectedTabIndex(newValue)}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                        sx={{ width: "100%" }}
                    >
                        {selectedRowData?.audit_logs?.map((log, index) => (
                            <Tab
                                key={index}
                                label={`Rule ${index + 1}`}
                            />
                        ))}
                    </Tabs>
                    {selectedLog && (
                        <Box sx={{ padding: '1rem' }}>
                            <>
                                {isWafAdmin && renderFieldWithNA("Rule ID", selectedLog.rule_id)}
                                {renderFieldWithNA("Attack Category", selectedLog.attack_category)}
                                {renderFieldWithNA("Data", selectedLog.data)}
                                {renderFieldWithNA("Impact", selectedLog.impact)}
                                {renderFieldWithNA("Message", selectedLog.message)}
                                {renderFieldWithNA("Recommendation", selectedLog.recommendation)}
                            </>
                        </Box>
                    )}
                    </Box>
                </Box>
            </Box>
        </Drawer>
    )
}

export default MoreInfo
