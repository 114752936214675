import { Box, Button, TableCell, TableContainer, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";


export const  generateMediaQueryStyles = (property, value)=> {
    // Define breakpoints with their respective min and max widths

    const propertyValue = `${value}rem`;
    const breakpoints = [
        { name: 'xsmall', min: 320, max: 575, factor: 0.6 },
        { name: 'small', min: 576, max: 767, factor: 0.65 },
        { name: 'medium', min: 768, max: 991, factor: 0.7 },
        { name: 'large', min: 992, max: 1199, factor: 0.75 },
        { name: 'xlarge', min: 1200, max: 1399, factor: 0.85 },
        { name: 'xxlarge', min: 1400, max: 1599, factor: 0.95 },
        { name: 'xxxlarge', min: 1600, max: 1799, factor: 1 },
        { name: 'xxxxlarge', min: 1800, max: 1999, factor: 1.1 },
        { name: 'xxxxxlarge', min: 2000, max: 2199, factor: 1.2 },
        { name: 'xxxxxxlarge', min: 2200, max: 2399, factor: 1.3 },
        { name: 'xxxxxxxlarge', min: 2400, max: 2599, factor: 1.4 },
        { name: 'xxxxxxxxlarge', min: 2600, max: 2799, factor: 1.5 },
        { name: 'xxxxxxxxxlarge', min: 2800, max: 2999, factor: 1.6 },
        { name: 'xxxxxxxxxxlarge', min: 3000, max: 3199, factor: 1.7 },
        { name: 'xxxxxxxxxxxlarge', min: 3200, max: 3499, factor: 1.8 },
        { name: 'xxxxxxxxxxxxlarge', min: 3500, max: 3799, factor: 1.9 },
        { name: 'xxxxxxxxxxxxxlarge', min: 3800, max: 4000, factor: 2 },
        { name: 'xxxxxxxxxxxxxxlarge', min: 3800, max: "", factor: 2 },
    ];
    
  
    let css = '';
  
    breakpoints.forEach((breakpoint) => {
      css += `@media only screen and (min-width:${breakpoint.min}px) and (max-width:${breakpoint.max}px) {
                ${property}: calc(${propertyValue} * ${breakpoint.factor}) !important;
            }`
    });
  
    return css;
  }
  
// interface HeadingProps {
// className?: string; 
// }

export const StyledMuiIcon = (IconComponent) => styled(IconComponent)(({ theme }) => ({
  
  fontSize:"1.5rem !important",
  "@media (min-width:2560px)":{
    fontSize: "3rem !important",
  },
  "@media (min-width:3200px)":{
    fontSize: "3.5rem !important",
  }
  
}));

export const StyledLogo = styled("img")(({theme})=>({
  "@media (max-width:600px)":{
    width:"5rem",
  },
  "@media (min-width:601px)":{
    width:"6rem",
  },
  "@media (min-width:768px)":{
    width:"7rem",
  },
  "@media (min-width:1024px)":{
    width:"8rem",
  },
  "@media (min-width:1366px)":{
    width:"9rem",
  },
  "@media (min-width:2560px)":{
    width:"10rem",
  },
  "@media (min-width:3200px)":{
    width:"11rem",
  },

}))
  

export const StyledImg = styled('img')(({ theme }) => ({
  width: '1.35rem !important',
  "@media (min-width:2560px)":{
    width:"3rem !important"
  },
  "@media (min-width:3200px)":{
    width: "3.5rem !important",
  }
}));


export const HeadingStyled = styled(Typography)<TypographyProps & { className?: string | string[] }>`
  font-weight:600;
  /* Use the generateMediaQueryStyles function to apply responsive font-size */
  ${generateMediaQueryStyles('font-size',1.6)}
`;


export const CustomButton = styled(Button)`
    ${generateMediaQueryStyles('font-size',1.2)}
    ${generateMediaQueryStyles('padding-left',1.2)}
    ${generateMediaQueryStyles('padding-right',1.2)}
    ${generateMediaQueryStyles('padding-top',0.1)}
    ${generateMediaQueryStyles('padding-bottom',0.1)}
    ${generateMediaQueryStyles('border-radius',1)}
    color: #FFFFFF; /* Add the color property here */

`
export const CustomDiv = styled(Box)`
    
    ${generateMediaQueryStyles('padding-top',2.6)}

`

export const CustomTableContainer = styled(TableContainer)`

  height:100%;
  background-color:#FFFFFF;
  box-shadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2)';
  overflow-x:hidden;
  overflow-y:auto;
  ${generateMediaQueryStyles('max-height',29)}
  &:-webkit-scrollbar{
    display: none;
  }
`
export const CustomTableHead = styled(TableCell)`
  background-color: hsl(200, 54%, 51%) !important;
  color:#F1F1F1;
  line-height:100%;
  ${generateMediaQueryStyles('font-size',1.2)}
`
export const CustomPopUp = styled(Box)`
  
  max-height:25rem;
  ${generateMediaQueryStyles('width',30)}
  
`
export const PopUpHeading = styled(Typography)`
  color:#FFFFFF;
  line-height:100%;
  font-weight:600;
  ${generateMediaQueryStyles('font-size',1.3)}
`
export const CustomFormLabel = styled(Typography)`
  line-height:100%;
  font-weight:600;
  ${generateMediaQueryStyles('font-size',1.2)}
`

export const CustomTableCell = styled(TableCell)`
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  color:#22323b;
  font-style: normal;
  & .MuiTableCell-root{
  }
  ${generateMediaQueryStyles('font-size',1.2)}
  
`