import React, { useEffect, useState } from 'react';
import { Container, Typography, Checkbox, Button, Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import cybersophyLogo from '../../assets/svg/cwLogo.svg'
import { request } from '../../services/request';
import { fetchFileText } from '../../services/UploadFile';

// Styled component for content area with scrolling
const ContentArea = styled('div')({
  height: '100%', // Set your desired height for content area
  maxHeight:"400px",
  padding: '30px', // Adjusted padding for top/bottom 50px, left/right 30px
  overflowY: 'auto',
  color:'#8D8D8D',
  backgroundColor: '#E3F2FD',
  marginBottom: '1rem',
  marginTop:'1rem',
  borderRadius:'1rem',
  '&::-webkit-scrollbar': {
    width: '4rem', // Increase width to accommodate padding
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#2196f3', // Blue color for the scrollbar
    borderRadius: '100px',
    backgroundClip: 'padding-box', // Prevent the border from increasing the thumb's size
    border: '28px solid transparent', // Add padding by using border with the same color as background
  },
  '&::-webkit-scrollbar-track': {
    background: '#CCCCCC', // Grey color for the scrollbar track
    borderRadius: '100px', // Rounded corners for the track
    border: '28px solid #E3F2FD', // Add padding by using border with the same color as background
  },
});

// Create makeStyles hook for styles
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop:"2rem",
    overflowY:'auto',

  },
  modalStyles:{
    backgroundColor:"#fff !important",
    outline: 'none !important',
    border: 'none !important',
    boxShadow: 'none', // Ensure there is no box shadow
  },
  logo:{
    width:'20%',
    marginBottom:'20px'
    
  },
  box: {
    padding: '1rem',
    borderRadius: '10px',
   },
  contentAreaBox:{
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#E3F2FD',
    borderRadius:'1rem',
    padding:'0.01rem',
    marginTop: '2rem',
  },
  backdrop: {
    backgroundColor: '#FFFFFF !important', // White color for the backdrop
    height:"100vh"
  
  },
  eulaTitle:{
    fontSize:"1.3rem !important",
    fontStyle:"normal",
    color: '#0046FF !important',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    color:'#8D8D8D',
    fontWeight:400,
    marginLeft:'-10px !important',
  },
  buttonBox: {
    marginTop: '20px',
  },
  acceptButton: {
    marginLeft: '2rem !important',
    backgroundColor:'#0046FF !important',
    color:'#FFFFFF !important',
    padding:"1% 7% 1% 7% !important",
    borderRadius:'1rem !important',
    fontSize:'1rem !important',
    '&:disabled': {
      backgroundColor: '#cccccc !important', // Custom disabled color
      color: '#666666 !important', // Custom disabled text color
    },
  },

  declineButton:{
    padding:"1% 7% 1% 7% !important",
    color:"#0046FF !important",
    borderColor:"#0046FF !important",
    borderRadius:'1rem !important',
    fontSize:'1rem !important'
  }
}));

interface EulaModalProps {
    isOpen: boolean;
    onAccept: () => void;
    onReject: () => void;
  }
  
const EulaModal: React.FC<EulaModalProps> = ({ isOpen, onAccept, onReject }) => {
    const [agree, setAgree] = useState(false);
    const [eulaParagraphs, setEulaParagraphs] = useState<string[]>([]);
    const classes = useStyles();

    useEffect(() => {
      const fetchEulaText = async () => {
        try {
  
          const eula_id = `${process.env.REACT_APP_EULA_ID}`;
          
          // Check if the EULA ID has changed
          const storedEulaId = localStorage.getItem('eula_id');
          console.log(" eula in storage",storedEulaId)
          if (storedEulaId && storedEulaId !== eula_id) {
            // EULA ID has changed, reset the acceptance status
            await request.post(`${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}`, { reset: false,user_id:'none'});
  
            // Update the stored EULA ID
            localStorage.setItem('eula_id', eula_id);
          } else {
            // EULA ID has not changed, just set it if not already stored
            localStorage.setItem('eula_id', eula_id);
          }
  
          const response = await fetchFileText(eula_id);
          const paragraphs = response.split('\n'); // Split text into paragraphs and remove empty ones
          setEulaParagraphs(paragraphs);
          // setEulaText(response);
        } catch (error) {
          console.error('Failed to fetch EULA text:', error);
          setEulaParagraphs(['Failed to load EULA text.']); // Fallback text if fetch fails
          // setEulaText('Failed to load EULA text.');
        }
      };
  
      if (isOpen) {
        fetchEulaText();
      }
    }, [isOpen]);

    // const handleDownload = async () => {
    //   try {
    //       const blob = new Blob([eulaText], { type: 'text/plain' });
    //       const url = window.URL.createObjectURL(blob);
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.setAttribute('download', 'EULA.txt');
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //   //   const response = await axios.get('/api/eula/download', { responseType: 'blob' });
    //   //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   //   const link = document.createElement('a');
    //   //   link.href = url;
    //   //   link.setAttribute('download', 'EULA.pdf');
    //   //   document.body.appendChild(link);
    //   //   link.click();
    //   //   link.remove();
    //   } catch (error) {
    //     console.error('Failed to download EULA:', error);
    //     // Handle error (show message to user, etc.)
    //   }
    // };

  const handleCheckboxChange = (event) => {
    setAgree(event.target.checked);
  };
 if(isOpen){
  return (
  
  <Box className={classes.backdrop}>
    <Container maxWidth="lg" className={classes.container}>
    <img className={classes.logo} src={cybersophyLogo} alt="cybersophy-logo"/>

      <Box className={classes.box}>
        <Typography sx={{fontWeight:600}} className={classes.eulaTitle} variant="h4" align="center">End-User License Agreement (EULA)</Typography>
        <Box className={classes.contentAreaBox}>
        <ContentArea>
          {/* <Typography variant="body1">{eulaText}</Typography> */}
            {eulaParagraphs.map((paragraph, index) => (
              <Typography 
                key={index} variant="body1" component="p">
                {paragraph.replace(/\\n/g, '')}
              </Typography>
            ))}
        </ContentArea>
        </Box>
        <div className={classes.checkboxContainer}>
          <Checkbox
            id="agree-checkbox"
            checked={agree}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="agree-checkbox">I have read and agree to the End-User License Agreement.</label>
        </div>
        <Box className={classes.buttonBox}>
         <Button
            id="decline-btn"
            variant="outlined"
            className={classes.declineButton}
            onClick={onReject}
          >
            Decline
          </Button>
          <Button
            id="accept-btn"
            variant="contained"
            disabled={!agree}
            onClick={onAccept} 
            className={classes.acceptButton}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Container>
  </Box>
  );
 }else{
  return null
 }
}

export default EulaModal;
