import { IconButton, Popover, TextField } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import CONSTANTS, { URL_PATTERN } from "../../../constants/constants"
import { addOrEditOrDeleteClient } from "./clientServices"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useFormik } from "formik"
import * as Yup from 'yup';
import { pushNotification } from "../../../redux/notification/notificationActions"



const UrlTooltip =({clientId,handleGetAllurls})=>{

    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); // Store anchor element reference

    const handleAddCircleButtonClick = (event) => {
        setAnchorEl(event.currentTarget); 
        setPopoverOpen(true);
      };
    
      const handleClosePopover = () => {
        setPopoverOpen(false);
      };

    const dispatch = useDispatch()

    const [initialValues, setInitialValues] = useState({
        client_id:clientId,
        domain_name:""
        
      });

      const formPayload = (values: any) => ({
        domain_name:values.domain_name,
        client_id: values.client_id
        
      });

      const postFormValues = (values: any) => {
        const postData = formPayload(values);
        console.log("postdata ",postData)
        handlePostApiRequest(postData);
      };

      const handlePostApiRequest = async (data) => {
        const response:any = await addOrEditOrDeleteClient("add-url",null,data)

        if (response?.success) {
          dispatch(
            pushNotification({
              isOpen: true,
              message: response.message,
              type: CONSTANTS.SUCCESS,
              })
            );
            handleGetAllurls();
          } else {
            dispatch(
              pushNotification({
                isOpen: true,
                message: response.error,
                type: CONSTANTS.ERROR,
              })
            );
          }
        };

      const handleSubmit = async (values: any, setSubmitting: any) => {
        setSubmitting(true);
        postFormValues(values);
      };

      const formOne = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            domain_name: Yup.string()
            .matches(URL_PATTERN,"Invalid URL")
            .trim()
            
        }),
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          },
          enableReinitialize: true,
      });


    return (
    <><IconButton onClick={handleAddCircleButtonClick}>
            <AddCircleOutlineIcon />
        </IconButton>
        <Popover 
            open={isPopoverOpen} 
            anchorEl={anchorEl} // Set anchor element reference
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            >
                <form onSubmit={formOne.handleSubmit} style={{ display: "flex" }}>
                        <div>
                        <TextField
                            id="new-url"
                            name="domain_name"
                            placeholder="Enter new URL"
                            size="small"
                            onBlur={formOne.handleBlur}
                            onChange={formOne.handleChange}
                            value={formOne.values.domain_name}
                            helperText={
                                formOne.touched.domain_name && formOne.errors.domain_name
                            }
                            error={
                                formOne.touched.domain_name && Boolean(formOne.errors.domain_name)
                            }
                            sx={{ width: "12rem" }}
                            />
                        </div>

                        <button type="submit"  disabled={formOne.isSubmitting}>
                            Add URL
                        </button>
                    </form>
            </Popover></>
       
    )

}

export default UrlTooltip