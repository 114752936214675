
import {
  TASKMANAGEMENT_USERS_ENDPOINT,
  TASKMANAGEMENT_USER_ENDPOINT,
  USERS_ENDPOINT,
  USERS_GROUPS,
  USERS_ROLES,
  START_PROCESS,
  USER_PROCESS_DEFINITION_KEY,
  USER_PREFERENCE,
} from "../../../constants/endpoints";

import { request, ResponseProps } from "../../../services/request";

interface Id {
  id: string;
}
interface EditUserData {
  userName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailId: string;
  department: string;
  groups: string[];
  roles: string[];
}

export interface UserData extends Id, EditUserData {}

export interface UserInfo {
  totalElements: number;
  page: number;
  size: number;
  content: UserData[] | [];
}

export interface EditUserDataResponse extends Id {
  userId: string;
  userData: EditUserData;
}

export interface FormioSubmissionData extends UserData {
  submit: boolean;
}

type Actions = "add" | "update" | "delete";
interface AddOrEditOrDeleteUserReqObjProps {
  processDefinitionKey: string;
  businessKey: string;
  variables: {
    action: Actions;
    userId?: string;
    userData?: string;
  };
}

const GATEWAY_URL = `${process.env.REACT_APP_API_GATEWAY_URL}/`
// const GATEWAY_URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}`


export const getUserPreferences = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${USER_PREFERENCE}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getAllUsers = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {

  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${TASKMANAGEMENT_USERS_ENDPOINT}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getUserLoggedIn = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${TASKMANAGEMENT_USER_ENDPOINT}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getAllUsersWithPagination = async (
  page: number,
  size: number,
  searchTerm?: string
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const search = searchTerm ? `&q=${searchTerm}` : "";
  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${USERS_ENDPOINT}?only-mandatory-fields=false&page=${page}&size=${size}${search}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    // console.log("data",r.data)
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getAllGroups = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${USERS_GROUPS}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getAllRoles = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${USERS_ROLES}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};


export const addOrEditOrDeleteUser = async (
  action: "add" | "update" | "delete",
  id?: string | null,
  userData?: FormioSubmissionData | null,
  userName?: string | null
): Promise<{ success: boolean; message?: string }> => {
  let reqObj = {};
  // console.log("action,id,userdata,username ",action,id,userData,userName)

  let successMessage = "";
  const generateReqObj = (
    username: string,
    userid: string | null,
    userdata: EditUserData | null
  ): AddOrEditOrDeleteUserReqObjProps => {
    const data: {
      action: "add" | "update" | "delete";
      userId?: string;
      userData?: string;
    } = { action };
    
    if (userid) {
      data.userId = userid;
    }
    if (userdata) {
      data.userData = JSON.stringify(userdata);
      
    }

    return {
      processDefinitionKey: USER_PROCESS_DEFINITION_KEY,
      businessKey: username,
      variables: {
        ...data,
      },
    };
  };
  if (action === "add" && userData) {
    // delete userData.submit;
    reqObj = generateReqObj(userData.userName, null, userData);
    successMessage = "User added successfully";
  }
  if (action === "update" && id && userData) {
    // delete userData.submit;
    successMessage = "User updated successfully";
    reqObj = generateReqObj(userData.userName, id, userData);
  }
  if (action === "delete" && id && userName) {
    // console.log("in delete ", id ,userName)
    successMessage = "User deleted successfully";
    reqObj = generateReqObj(userName, id, null);
  }

  const res: ResponseProps = (await request.post(
    `${GATEWAY_URL}${START_PROCESS}`,
    reqObj
  )) as ResponseProps;
  // console.log("reqObj ",reqObj)
  if (res.success) {
    return { success: true, message: successMessage };
  }
  return { success: false, message: res.message };
};

export const getUserDetails = async (
  id: string
): Promise<{
  success: boolean;
  message?: string;
  data?: EditUserDataResponse;
}> => {
  
  const r: ResponseProps = (await request.get(
    `${GATEWAY_URL}${USERS_ENDPOINT}/${id}`
  )) as ResponseProps;
  if (r.success) {
    const data: EditUserDataResponse = r.data as EditUserDataResponse;
    return { success: true, message: r.message, data: data };
  }
  return { success: false };
};



export const getAllUsersWithKeycloakId = async(emailId)=>{

    const params = `auth/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users?briefRepresentation=true&email=${emailId}`


    const userIdUrl = `${process.env.REACT_APP_KEYCLOAK_URL}${params}`

    const response:any = await request.get(userIdUrl)
    return response


}