import React from 'react';
import { Button } from '@mui/material';


const  GRADIENT = ['#1FA7D3', '#BED9E3'];

const commonButtonStyles = {
  cursor: 'pointer',
  textDecoration: 'none',
  textTransform: 'none',
};

interface ButtonWithUnderlineProps {
  text:string;
  id:string;
  isActive:boolean;
  onClick:any;

}

const ButtonWithUnderline = ({ id,text, isActive, onClick }:ButtonWithUnderlineProps) => {
  // console.log("text ",text,isActive)
  return (
    <Button
      id={id}
      variant='outlined'
      sx={{
        ...commonButtonStyles,
        // color:isActive?'#FFFFFF':'#6A6A6A',
        color:isActive?'#1FA8D3':'#6A6A6A',
        position: 'relative',
        fontWeight:600,
        // backgroundColor:"#BED9E3",
        // background: `linear-gradient(to right, ${GRADIENT.join(',')})`,
        zIndex:1,
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: isActive ? '0.2rem' : '',
          backgroundColor: '#1888b4',
          bottom: 0,
          left: 0,
          transition: 'height 0.3s',
        },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default ButtonWithUnderline;
