import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// import { StyledEngineProvider } from '@mui/material/styles';
import './i18nextInit'


ReactDOM.render(
    <React.StrictMode>
         
        <Router>
        {/* <StyledEngineProvider injectFirst> */}
            <App />
        {/* </StyledEngineProvider> */}
        </Router>
        
    </React.StrictMode>,
    document.getElementById('root'),
);
