import { Box, Card, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import SwapVertIcon from '@mui/icons-material/SwapVert'; // Import the swap vertical icon

import '../../maincontent/mainContentstyles.css';
import { formatNumber } from "../../utils/formatNumber";
import { ALL_COUNTRY_SHORT_CODES } from "../../../../constants/countryCodes";
import PreLoader from "../../../../components/common/preLoader";
const GRADIENT_COLORS = ['#FFFFFF', '#BED9E3'];

interface CountryListProps {
  id:string;
  countries:any
}

export const CountryList = ({id, countries }:CountryListProps) => {
  const [sortOrder, setSortOrder] = useState('desc'); // State to track sort order

  const handleSort = () => {
    // Toggle sort order between 'asc' and 'desc'
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortedCountries = Object.keys(countries).sort((a, b) => {
    // Use the sort order to determine how to sort the countries
    if (sortOrder === 'asc') {
      return countries[a] - countries[b];
    } else {
      return countries[b] - countries[a];
    }
  });

  return (
    <Box
      id={id}
      sx={{
        height: {xs:"19rem",xl:"25rem"},
        width: '100%',
        overflow: "auto",
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      
      <Card
        id="dashboard-countries-box"
        sx={{
          borderRadius:"0.5rem",
          height: "100%",
          width: "100% !important",
          boxShadow: 'none',
          borderLeft: "1px solid #FFFFFF",
          borderTop: "1px solid #FFFFFF",
          background: `linear-gradient(to bottom, ${GRADIENT_COLORS.join(',')})`,
          p: '1rem',
          flexGrow: "1 !important",
          overflow: "auto"
        }}
      >
        <Typography id="countries-title" variant="h2" lineHeight={'2rem'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Countries
          <IconButton  id="sort-button" title="sort by attacks" onClick={handleSort} sx={{ color:"#1FA7D3",cursor: 'pointer' }}>
              <SwapVertIcon id="sort-icon" /> {/* Swap icon to trigger sorting */}
          </IconButton>
        </Typography>


        {Object?.keys(countries)?.length ? (
          sortedCountries?.map(countryCode => {
            const total = countries[countryCode];
            const countryInfo = ALL_COUNTRY_SHORT_CODES.find(info => info?.cca2 === countryCode);

            if (countryInfo) {
              return (
                <Box
                  id={`country-${countryCode}`}
                  key={countryCode}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                  }}
                >
                  <Box id={`country-flag-${countryCode}`} sx={{ flex: '0 0 1rem' }}>{countryInfo.flag}</Box>
                  <Typography  id={`country-name-${countryCode}`} variant="h2" sx={{ color: "#4D4D4E", flex: '0 0 60%' }}>{countryInfo.name}</Typography>
                  <Box id={`countries-total-${countryCode}`} title={total} sx={{ flex: '0 0 20%' }}>{formatNumber(total)}</Box>
                </Box>
              );
            }
            return null;
          })
        ) : (
          <Typography id="no-data-available" variant="body1" align="center">
            No data available
          </Typography>
        )}
      
      </Card>
      
    </Box>
  );
};
