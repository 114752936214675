import React from 'react'

import { Menu, MenuItem, Typography } from '@mui/material';
import { useKeycloak } from "@react-keycloak/web";
import CONSTANTS from '../../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface LogoutMenuProps {
  anchorEl:any;
  handleMenuClose:any;
}

const LogoutMenu = ({anchorEl,handleMenuClose}:LogoutMenuProps) => {


    const menuId = "primary-search-account-menu";
    const { keycloak } = useKeycloak();
    const navigate = useNavigate()
    const { t } = useTranslation();

    const handleLogout = () => {
      navigate("/")
      sessionStorage.clear();
      keycloak.logout();
    };  

  return (

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {keycloak.authenticated ? (
        <>
          <MenuItem id={`${menuId}-menu-item`} sx={{ display: { xs: "flex", md: "none" } }}>
            <Typography  id={`${menuId}-user-name`}  variant='h2'>
              {sessionStorage.getItem(CONSTANTS.FIRST_NAME)}{" "}
              {sessionStorage.getItem(CONSTANTS.LAST_NAME)}
            </Typography>
          </MenuItem>
          <MenuItem id={`${menuId}-logout-menu-item`} onClick={handleLogout}>
            <Typography id={`${menuId}-logout`}  variant='h2' sx={{fontWeight: 500,}}>{t(CONSTANTS.LOGOUT)}</Typography>
          </MenuItem>
        </>
      ) : null}
    </Menu>
    
  )
}

export default LogoutMenu
